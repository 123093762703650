@import './Variables';

/** Bootstrap (https://getbootstrap.com/)  */
:root {
  --blue: #559bfb;
  --indigo: #2c3782;
  --purple: #816bff;
  --pink: #ff63a5;
  --red: #e85347;
  --orange: #ffa353;
  --yellow: #f4bd0e;
  --green: #1ee0ac;
  --teal: #20c997;
  --cyan: #09c2de;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #6576ff;
  --secondary: #364a63;
  --success: #1ee0ac;
  --info: #09c2de;
  --warning: #f4bd0e;
  --danger: #e85347;
  --dark: #1c2b46;
  --gray: #8091a7;
  --light: #e5e9f2;
  --lighter: #f5f6fa;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --breakpoint-xxl: 1540px;
  --font-family-sans-serif: (Roboto, sans-serif), 'Helvetica Neue', Arial,
    'Noto Sans', sans-serif;
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    'Liberation Mono', 'Courier New', monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

body {
  margin: 0;
  font-family: Roboto, sans-serif, 'Helvetica Neue', Arial, 'Noto Sans',
    sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.65;
  color: #526484;
  text-align: left;
  background-color: #f5f6fa;
}

[tabindex='-1']:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #798bff;
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  color: #465fff;
  text-decoration: underline;
}

a:not([href]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono',
    'Courier New', monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #8094ae;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 16px;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role='button'] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type='button']:not(:disabled),
[type='reset']:not(:disabled),
[type='submit']:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type='radio'],
input[type='checkbox'] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}

[type='search'] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 0.5rem;
  font-family: var(--default-font), sans-serif;
  font-weight: 700;
  line-height: 1.1;
  color: #364a63;
}

h1,
.h1 {
  font-size: 2rem;
}

h2,
.h2 {
  font-size: 1.75rem;
}

h3,
.h3 {
  font-size: 1.5rem;
}

h4,
.h4 {
  font-size: 1.25rem;
}

h5,
.h5 {
  font-size: 1.15rem;
}

h6,
.h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.00625rem;
  font-weight: 400;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.1;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.1;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.1;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.1;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid #b7c2d0;
}

small,
.small {
  font-size: 85%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.09375rem;
}

.blockquote-footer {
  display: block;
  font-size: 85%;
  color: #b6c6e3;
}

.blockquote-footer::before {
  content: '\2014\00A0';
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #f5f6fa;
  border: 1px solid #dee2e6;
  border-radius: 4px;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #ff63a5;
  word-wrap: break-word;
}

a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 3px;
}

kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl,
.container-xxl {
  width: 100%;
  padding-right: 14px;
  padding-left: 14px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container,
  .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container,
  .container-sm,
  .container-md {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 1140px;
  }
}

@media (min-width: 1540px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl,
  .container-xxl {
    max-width: 1440px;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -14px;
  margin-left: -14px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*='col-'] {
  padding-right: 0;
  padding-left: 0;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto,
.col-xxl-1,
.col-xxl-2,
.col-xxl-3,
.col-xxl-4,
.col-xxl-5,
.col-xxl-6,
.col-xxl-7,
.col-xxl-8,
.col-xxl-9,
.col-xxl-10,
.col-xxl-11,
.col-xxl-12,
.col-xxl,
.col-xxl-auto {
  position: relative;
  width: 100%;
  padding-right: 14px;
  padding-left: 14px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%;
}

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%;
}

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%;
}

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333%;
}

.offset-2 {
  margin-left: 16.66667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333%;
}

.offset-5 {
  margin-left: 41.66667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333%;
}

.offset-8 {
  margin-left: 66.66667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333%;
}

.offset-11 {
  margin-left: 91.66667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333%;
  }
  .offset-sm-2 {
    margin-left: 16.66667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333%;
  }
  .offset-sm-5 {
    margin-left: 41.66667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333%;
  }
  .offset-sm-8 {
    margin-left: 66.66667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333%;
  }
  .offset-sm-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333%;
  }
  .offset-md-2 {
    margin-left: 16.66667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333%;
  }
  .offset-md-5 {
    margin-left: 41.66667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333%;
  }
  .offset-md-8 {
    margin-left: 66.66667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333%;
  }
  .offset-md-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333%;
  }
  .offset-lg-2 {
    margin-left: 16.66667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333%;
  }
  .offset-lg-5 {
    margin-left: 41.66667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333%;
  }
  .offset-lg-8 {
    margin-left: 66.66667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333%;
  }
  .offset-lg-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333%;
  }
  .offset-xl-2 {
    margin-left: 16.66667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333%;
  }
  .offset-xl-5 {
    margin-left: 41.66667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333%;
  }
  .offset-xl-8 {
    margin-left: 66.66667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333%;
  }
  .offset-xl-11 {
    margin-left: 91.66667%;
  }
}

@media (min-width: 1540px) {
  .col-xxl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xxl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
  }
  .col-xxl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
  }
  .col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xxl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-xxl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-xxl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xxl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-xxl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .col-xxl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xxl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
  .col-xxl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
  }
  .col-xxl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xxl-first {
    order: -1;
  }
  .order-xxl-last {
    order: 13;
  }
  .order-xxl-0 {
    order: 0;
  }
  .order-xxl-1 {
    order: 1;
  }
  .order-xxl-2 {
    order: 2;
  }
  .order-xxl-3 {
    order: 3;
  }
  .order-xxl-4 {
    order: 4;
  }
  .order-xxl-5 {
    order: 5;
  }
  .order-xxl-6 {
    order: 6;
  }
  .order-xxl-7 {
    order: 7;
  }
  .order-xxl-8 {
    order: 8;
  }
  .order-xxl-9 {
    order: 9;
  }
  .order-xxl-10 {
    order: 10;
  }
  .order-xxl-11 {
    order: 11;
  }
  .order-xxl-12 {
    order: 12;
  }
  .offset-xxl-0 {
    margin-left: 0;
  }
  .offset-xxl-1 {
    margin-left: 8.33333%;
  }
  .offset-xxl-2 {
    margin-left: 16.66667%;
  }
  .offset-xxl-3 {
    margin-left: 25%;
  }
  .offset-xxl-4 {
    margin-left: 33.33333%;
  }
  .offset-xxl-5 {
    margin-left: 41.66667%;
  }
  .offset-xxl-6 {
    margin-left: 50%;
  }
  .offset-xxl-7 {
    margin-left: 58.33333%;
  }
  .offset-xxl-8 {
    margin-left: 66.66667%;
  }
  .offset-xxl-9 {
    margin-left: 75%;
  }
  .offset-xxl-10 {
    margin-left: 83.33333%;
  }
  .offset-xxl-11 {
    margin-left: 91.66667%;
  }
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #526484;
}

.table th,
.table td {
  padding: 0.5rem;
  vertical-align: top;
  border-top: 1px solid #dbdfea;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dbdfea;
}

.table tbody + tbody {
  border-top: 2px solid #dbdfea;
}

.table-sm th,
.table-sm td {
  padding: 0.25rem;
}

.table-bordered {
  border: 1px solid #dbdfea;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #dbdfea;
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #f5f6fa;
}

.table-hover tbody tr:hover {
  color: #526484;
  background-color: #f5f6fa;
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #d4d9ff;
}

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #afb8ff;
}

.table-hover .table-primary:hover {
  background-color: #bbc2ff;
}

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #bbc2ff;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #c7ccd3;
}

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #96a1ae;
}

.table-hover .table-secondary:hover {
  background-color: #b9bfc8;
}

.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #b9bfc8;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c0f6e8;
}

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8aefd4;
}

.table-hover .table-success:hover {
  background-color: #aaf3e0;
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #aaf3e0;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #baeef6;
}

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #7fdfee;
}

.table-hover .table-info:hover {
  background-color: #a3e8f3;
}

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #a3e8f3;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fcedbc;
}

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #f9dd82;
}

.table-hover .table-warning:hover {
  background-color: #fbe6a4;
}

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #fbe6a4;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f9cfcb;
}

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #f3a69f;
}

.table-hover .table-danger:hover {
  background-color: #f6bab4;
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f6bab4;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #bfc4cb;
}

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #89919f;
}

.table-hover .table-dark:hover {
  background-color: #b1b7c0;
}

.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b1b7c0;
}

.table-gray,
.table-gray > th,
.table-gray > td {
  background-color: #dbe0e6;
}

.table-gray th,
.table-gray td,
.table-gray thead th,
.table-gray tbody + tbody {
  border-color: #bdc6d1;
}

.table-hover .table-gray:hover {
  background-color: #ccd3dc;
}

.table-hover .table-gray:hover > td,
.table-hover .table-gray:hover > th {
  background-color: #ccd3dc;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #f8f9fb;
}

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #f1f4f8;
}

.table-hover .table-light:hover {
  background-color: #e8ebf2;
}

.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #e8ebf2;
}

.table-lighter,
.table-lighter > th,
.table-lighter > td {
  background-color: #fcfcfe;
}

.table-lighter th,
.table-lighter td,
.table-lighter thead th,
.table-lighter tbody + tbody {
  border-color: #fafafc;
}

.table-hover .table-lighter:hover {
  background-color: #e9e9f8;
}

.table-hover .table-lighter:hover > td,
.table-hover .table-lighter:hover > th {
  background-color: #e9e9f8;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: #f5f6fa;
}

.table-hover .table-active:hover {
  background-color: #e4e7f2;
}

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: #e4e7f2;
}

.table .thead-dark th {
  color: #fff;
  background-color: #2b3748;
  border-color: #3c4d62;
}

.table .thead-light th {
  color: #364a63;
  background-color: #f5f6fa;
  border-color: #dbdfea;
}

.table-dark {
  color: #fff;
  background-color: #2b3748;
}

.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #3c4d62;
}

.table-dark.table-bordered {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: #344357;
}

.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: #344357;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1539.98px) {
  .table-responsive-xxl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xxl > .table-bordered {
    border: 0;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(2.125rem + 2px);
  padding: 0.4375rem 1rem;
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1.25rem;
  color: #3c4d62;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #dbdfea;
  border-radius: 4px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #3c4d62;
}

.form-control:focus {
  color: #3c4d62;
  background-color: #fff;
  border-color: #6576ff;
  outline: 0;
  box-shadow: 0 0 0 3px rgba(101, 118, 255, 0.1);
}

.form-control::placeholder {
  color: #b6c6e3;
  opacity: 1;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #f5f6fa;
  opacity: 1;
}

input[type='date'].form-control,
input[type='time'].form-control,
input[type='datetime-local'].form-control,
input[type='month'].form-control {
  appearance: none;
}

select.form-control:focus::-ms-value {
  color: #3c4d62;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.4375rem + 1px);
  padding-bottom: calc(0.4375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.25rem;
}

.col-form-label-lg {
  padding-top: calc(0.6875rem + 1px);
  padding-bottom: calc(0.6875rem + 1px);
  font-size: 0.9375rem;
  line-height: 1.25rem;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.75rem;
  line-height: 1.25rem;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.4375rem 0;
  margin-bottom: 0;
  font-size: 0.8125rem;
  line-height: 1.25rem;
  color: #364a63;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext.form-control-sm,
.form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.75rem + 2px);
  padding: 0.25rem 1rem;
  font-size: 0.75rem;
  line-height: 1.25rem;
  border-radius: 3px;
}

.form-control-lg {
  height: calc(2.625rem + 2px);
  padding: 0.6875rem 1rem;
  font-size: 0.9375rem;
  line-height: 1.25rem;
  border-radius: 5px;
}

select.form-control[size],
select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row > .col,
.form-row > [class*='col-'] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}

.form-check-input[disabled] ~ .form-check-label,
.form-check-input:disabled ~ .form-check-label {
  color: #8094ae;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}

.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #1ee0ac;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.75rem;
  margin-top: 0.1rem;
  font-size: 0.8125rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(30, 224, 172, 0.9);
  border-radius: 3px;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid,
.form-control.is-valid {
  border-color: #1ee0ac;
  padding-right: 2.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%231ee0ac' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right add(0.3125rem, 0.21875rem) center;
  background-size: add(0.625rem, 0.4375rem) add(0.625rem, 0.4375rem);
}

.was-validated .form-control:valid:focus,
.form-control.is-valid:focus {
  border-color: #1ee0ac;
  box-shadow: 0 0 0 0.2rem rgba(30, 224, 172, 0.25);
}

.was-validated textarea.form-control:valid,
textarea.form-control.is-valid {
  padding-right: 2.125rem;
  background-position: top add(0.3125rem, 0.21875rem) right
    add(0.3125rem, 0.21875rem);
}

.was-validated .custom-select:valid,
.custom-select.is-valid {
  border-color: #1ee0ac;
  padding-right: calc(0.75em + 2.65625rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
      no-repeat right 1rem center/8px 10px,
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%231ee0ac' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e")
      #fff no-repeat center right 2rem add(0.625rem, 0.4375rem)
      add(0.625rem, 0.4375rem);
}

.was-validated .custom-select:valid:focus,
.custom-select.is-valid:focus {
  border-color: #1ee0ac;
  box-shadow: 0 0 0 0.2rem rgba(30, 224, 172, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label,
.form-check-input.is-valid ~ .form-check-label {
  color: #1ee0ac;
}

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip,
.form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label,
.custom-control-input.is-valid ~ .custom-control-label {
  color: #1ee0ac;
}

.was-validated .custom-control-input:valid ~ .custom-control-label::before,
.custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #1ee0ac;
}

.was-validated
  .custom-control-input:valid:checked
  ~ .custom-control-label::before,
.custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #4ae7bd;
  background-color: #4ae7bd;
}

.was-validated
  .custom-control-input:valid:focus
  ~ .custom-control-label::before,
.custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(30, 224, 172, 0.25);
}

.was-validated
  .custom-control-input:valid:focus:not(:checked)
  ~ .custom-control-label::before,
.custom-control-input.is-valid:focus:not(:checked)
  ~ .custom-control-label::before {
  border-color: #1ee0ac;
}

.was-validated .custom-file-input:valid ~ .custom-file-label,
.custom-file-input.is-valid ~ .custom-file-label {
  border-color: #1ee0ac;
}

.was-validated .custom-file-input:valid:focus ~ .custom-file-label,
.custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #1ee0ac;
  box-shadow: 0 0 0 0.2rem rgba(30, 224, 172, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #e85347;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.75rem;
  margin-top: 0.1rem;
  font-size: 0.8125rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(232, 83, 71, 0.9);
  border-radius: 3px;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  border-color: #e85347;
  padding-right: 2.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23e85347' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23e85347' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right add(0.3125rem, 0.21875rem) center;
  background-size: add(0.625rem, 0.4375rem) add(0.625rem, 0.4375rem);
}

.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus {
  border-color: #e85347;
  box-shadow: 0 0 0 0.2rem rgba(232, 83, 71, 0.25);
}

.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
  padding-right: 2.125rem;
  background-position: top add(0.3125rem, 0.21875rem) right
    add(0.3125rem, 0.21875rem);
}

.was-validated .custom-select:invalid,
.custom-select.is-invalid {
  border-color: #e85347;
  padding-right: calc(0.75em + 2.65625rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
      no-repeat right 1rem center/8px 10px,
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23e85347' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23e85347' stroke='none'/%3e%3c/svg%3e")
      #fff no-repeat center right 2rem add(0.625rem, 0.4375rem)
      add(0.625rem, 0.4375rem);
}

.was-validated .custom-select:invalid:focus,
.custom-select.is-invalid:focus {
  border-color: #e85347;
  box-shadow: 0 0 0 0.2rem rgba(232, 83, 71, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label,
.form-check-input.is-invalid ~ .form-check-label {
  color: #e85347;
}

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip,
.form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label,
.custom-control-input.is-invalid ~ .custom-control-label {
  color: #e85347;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label::before,
.custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #e85347;
}

.was-validated
  .custom-control-input:invalid:checked
  ~ .custom-control-label::before,
.custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #ee7d74;
  background-color: #ee7d74;
}

.was-validated
  .custom-control-input:invalid:focus
  ~ .custom-control-label::before,
.custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(232, 83, 71, 0.25);
}

.was-validated
  .custom-control-input:invalid:focus:not(:checked)
  ~ .custom-control-label::before,
.custom-control-input.is-invalid:focus:not(:checked)
  ~ .custom-control-label::before {
  border-color: #e85347;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label,
.custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #e85347;
}

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label,
.custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #e85347;
  box-shadow: 0 0 0 0.2rem rgba(232, 83, 71, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.form-inline .form-check {
  width: 100%;
}

@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-family: var(--default-font), sans-serif;
  font-weight: 700;
  color: #526484;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.4375rem 1.125rem;
  font-size: 0.8125rem;
  line-height: 1.25rem;
  border-radius: 4px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: #526484;
  text-decoration: none;
}

.btn:focus,
.btn.focus {
  outline: 0;
  box-shadow: 0 0 0 3px rgba(101, 118, 255, 0.1);
}

.btn.disabled,
.btn:disabled {
  opacity: 0.5;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #6576ff;
  border-color: #6576ff;
}

.btn-primary:hover {
  color: #fff;
  background-color: #3f54ff;
  border-color: #3249ff;
}

.btn-primary:focus,
.btn-primary.focus {
  color: #fff;
  background-color: #3f54ff;
  border-color: #3249ff;
  box-shadow: 0 0 0 0.2rem rgba(124, 139, 255, 0.5);
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #6576ff;
  border-color: #6576ff;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #3249ff;
  border-color: #253dff;
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(124, 139, 255, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #364a63;
  border-color: #364a63;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #29384a;
  border-color: #243142;
}

.btn-secondary:focus,
.btn-secondary.focus {
  color: #fff;
  background-color: #29384a;
  border-color: #243142;
  box-shadow: 0 0 0 0.2rem rgba(84, 101, 122, 0.5);
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #fff;
  background-color: #364a63;
  border-color: #364a63;
}

.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #243142;
  border-color: #202b3a;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(84, 101, 122, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #1ee0ac;
  border-color: #1ee0ac;
}

.btn-success:hover {
  color: #fff;
  background-color: #19be92;
  border-color: #18b389;
}

.btn-success:focus,
.btn-success.focus {
  color: #fff;
  background-color: #19be92;
  border-color: #18b389;
  box-shadow: 0 0 0 0.2rem rgba(64, 229, 184, 0.5);
}

.btn-success.disabled,
.btn-success:disabled {
  color: #fff;
  background-color: #1ee0ac;
  border-color: #1ee0ac;
}

.btn-success:not(:disabled):not(.disabled):active,
.btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #18b389;
  border-color: #16a881;
}

.btn-success:not(:disabled):not(.disabled):active:focus,
.btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(64, 229, 184, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #09c2de;
  border-color: #09c2de;
}

.btn-info:hover {
  color: #fff;
  background-color: #08a2b9;
  border-color: #0797ad;
}

.btn-info:focus,
.btn-info.focus {
  color: #fff;
  background-color: #08a2b9;
  border-color: #0797ad;
  box-shadow: 0 0 0 0.2rem rgba(46, 203, 227, 0.5);
}

.btn-info.disabled,
.btn-info:disabled {
  color: #fff;
  background-color: #09c2de;
  border-color: #09c2de;
}

.btn-info:not(:disabled):not(.disabled):active,
.btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #0797ad;
  border-color: #078ca1;
}

.btn-info:not(:disabled):not(.disabled):active:focus,
.btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(46, 203, 227, 0.5);
}

.btn-warning {
  color: #fff;
  background-color: #f4bd0e;
  border-color: #f4bd0e;
}

.btn-warning:hover {
  color: #fff;
  background-color: #d2a20a;
  border-color: #c69909;
}

.btn-warning:focus,
.btn-warning.focus {
  color: #fff;
  background-color: #d2a20a;
  border-color: #c69909;
  box-shadow: 0 0 0 0.2rem rgba(246, 199, 50, 0.5);
}

.btn-warning.disabled,
.btn-warning:disabled {
  color: #fff;
  background-color: #f4bd0e;
  border-color: #f4bd0e;
}

.btn-warning:not(:disabled):not(.disabled):active,
.btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: #fff;
  background-color: #c69909;
  border-color: #ba8f08;
}

.btn-warning:not(:disabled):not(.disabled):active:focus,
.btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(246, 199, 50, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #e85347;
  border-color: #e85347;
}

.btn-danger:hover {
  color: #fff;
  background-color: #e43325;
  border-color: #e02b1c;
}

.btn-danger:focus,
.btn-danger.focus {
  color: #fff;
  background-color: #e43325;
  border-color: #e02b1c;
  box-shadow: 0 0 0 0.2rem rgba(235, 109, 99, 0.5);
}

.btn-danger.disabled,
.btn-danger:disabled {
  color: #fff;
  background-color: #e85347;
  border-color: #e85347;
}

.btn-danger:not(:disabled):not(.disabled):active,
.btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #e02b1c;
  border-color: #d5281b;
}

.btn-danger:not(:disabled):not(.disabled):active:focus,
.btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(235, 109, 99, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #1c2b46;
  border-color: #1c2b46;
}

.btn-dark:hover {
  color: #fff;
  background-color: #111a2b;
  border-color: #0d1522;
}

.btn-dark:focus,
.btn-dark.focus {
  color: #fff;
  background-color: #111a2b;
  border-color: #0d1522;
  box-shadow: 0 0 0 0.2rem rgba(62, 75, 98, 0.5);
}

.btn-dark.disabled,
.btn-dark:disabled {
  color: #fff;
  background-color: #1c2b46;
  border-color: #1c2b46;
}

.btn-dark:not(:disabled):not(.disabled):active,
.btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #0d1522;
  border-color: #0a0f18;
}

.btn-dark:not(:disabled):not(.disabled):active:focus,
.btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(62, 75, 98, 0.5);
}

.btn-gray {
  color: #fff;
  background-color: #8091a7;
  border-color: #8091a7;
}

.btn-gray:hover {
  color: #fff;
  background-color: #697d97;
  border-color: #647790;
}

.btn-gray:focus,
.btn-gray.focus {
  color: #fff;
  background-color: #697d97;
  border-color: #647790;
  box-shadow: 0 0 0 0.2rem rgba(147, 162, 180, 0.5);
}

.btn-gray.disabled,
.btn-gray:disabled {
  color: #fff;
  background-color: #8091a7;
  border-color: #8091a7;
}

.btn-gray:not(:disabled):not(.disabled):active,
.btn-gray:not(:disabled):not(.disabled).active,
.show > .btn-gray.dropdown-toggle {
  color: #fff;
  background-color: #647790;
  border-color: #5f7189;
}

.btn-gray:not(:disabled):not(.disabled):active:focus,
.btn-gray:not(:disabled):not(.disabled).active:focus,
.show > .btn-gray.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(147, 162, 180, 0.5);
}

.btn-light {
  color: #101924;
  background-color: #e5e9f2;
  border-color: #e5e9f2;
}

.btn-light:hover {
  color: #101924;
  background-color: #ccd3e5;
  border-color: #c3cce1;
}

.btn-light:focus,
.btn-light.focus {
  color: #101924;
  background-color: #ccd3e5;
  border-color: #c3cce1;
  box-shadow: 0 0 0 0.2rem rgba(197, 202, 211, 0.5);
}

.btn-light.disabled,
.btn-light:disabled {
  color: #101924;
  background-color: #e5e9f2;
  border-color: #e5e9f2;
}

.btn-light:not(:disabled):not(.disabled):active,
.btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  color: #101924;
  background-color: #c3cce1;
  border-color: #bbc5dd;
}

.btn-light:not(:disabled):not(.disabled):active:focus,
.btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(197, 202, 211, 0.5);
}

.btn-lighter {
  color: #101924;
  background-color: #f5f6fa;
  border-color: #f5f6fa;
}

.btn-lighter:hover {
  color: #101924;
  background-color: #dcdfed;
  border-color: #d3d7e9;
}

.btn-lighter:focus,
.btn-lighter.focus {
  color: #101924;
  background-color: #dcdfed;
  border-color: #d3d7e9;
  box-shadow: 0 0 0 0.2rem rgba(211, 213, 218, 0.5);
}

.btn-lighter.disabled,
.btn-lighter:disabled {
  color: #101924;
  background-color: #f5f6fa;
  border-color: #f5f6fa;
}

.btn-lighter:not(:disabled):not(.disabled):active,
.btn-lighter:not(:disabled):not(.disabled).active,
.show > .btn-lighter.dropdown-toggle {
  color: #101924;
  background-color: #d3d7e9;
  border-color: #cbd0e5;
}

.btn-lighter:not(:disabled):not(.disabled):active:focus,
.btn-lighter:not(:disabled):not(.disabled).active:focus,
.show > .btn-lighter.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(211, 213, 218, 0.5);
}

.btn-outline-primary {
  color: #6576ff;
  border-color: #6576ff;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #6576ff;
  border-color: #6576ff;
}

.btn-outline-primary:focus,
.btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(101, 118, 255, 0.5);
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #6576ff;
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #6576ff;
  border-color: #6576ff;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(101, 118, 255, 0.5);
}

.btn-outline-secondary {
  color: #364a63;
  border-color: #364a63;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #364a63;
  border-color: #364a63;
}

.btn-outline-secondary:focus,
.btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(54, 74, 99, 0.5);
}

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  color: #364a63;
  background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #364a63;
  border-color: #364a63;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(54, 74, 99, 0.5);
}

.btn-outline-success {
  color: #1ee0ac;
  border-color: #1ee0ac;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #1ee0ac;
  border-color: #1ee0ac;
}

.btn-outline-success:focus,
.btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(30, 224, 172, 0.5);
}

.btn-outline-success.disabled,
.btn-outline-success:disabled {
  color: #1ee0ac;
  background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled):active,
.btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #1ee0ac;
  border-color: #1ee0ac;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(30, 224, 172, 0.5);
}

.btn-outline-info {
  color: #09c2de;
  border-color: #09c2de;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #09c2de;
  border-color: #09c2de;
}

.btn-outline-info:focus,
.btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(9, 194, 222, 0.5);
}

.btn-outline-info.disabled,
.btn-outline-info:disabled {
  color: #09c2de;
  background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled):active,
.btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #09c2de;
  border-color: #09c2de;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(9, 194, 222, 0.5);
}

.btn-outline-warning {
  color: #f4bd0e;
  border-color: #f4bd0e;
}

.btn-outline-warning:hover {
  color: #fff;
  background-color: #f4bd0e;
  border-color: #f4bd0e;
}

.btn-outline-warning:focus,
.btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(244, 189, 14, 0.5);
}

.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
  color: #f4bd0e;
  background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled):active,
.btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #fff;
  background-color: #f4bd0e;
  border-color: #f4bd0e;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(244, 189, 14, 0.5);
}

.btn-outline-danger {
  color: #e85347;
  border-color: #e85347;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #e85347;
  border-color: #e85347;
}

.btn-outline-danger:focus,
.btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(232, 83, 71, 0.5);
}

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  color: #e85347;
  background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled):active,
.btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #e85347;
  border-color: #e85347;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(232, 83, 71, 0.5);
}

.btn-outline-dark {
  color: #1c2b46;
  border-color: #1c2b46;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #1c2b46;
  border-color: #1c2b46;
}

.btn-outline-dark:focus,
.btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(28, 43, 70, 0.5);
}

.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
  color: #1c2b46;
  background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled):active,
.btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #1c2b46;
  border-color: #1c2b46;
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(28, 43, 70, 0.5);
}

.btn-outline-gray {
  color: #8091a7;
  border-color: #8091a7;
}

.btn-outline-gray:hover {
  color: #fff;
  background-color: #8091a7;
  border-color: #8091a7;
}

.btn-outline-gray:focus,
.btn-outline-gray.focus {
  box-shadow: 0 0 0 0.2rem rgba(128, 145, 167, 0.5);
}

.btn-outline-gray.disabled,
.btn-outline-gray:disabled {
  color: #8091a7;
  background-color: transparent;
}

.btn-outline-gray:not(:disabled):not(.disabled):active,
.btn-outline-gray:not(:disabled):not(.disabled).active,
.show > .btn-outline-gray.dropdown-toggle {
  color: #fff;
  background-color: #8091a7;
  border-color: #8091a7;
}

.btn-outline-gray:not(:disabled):not(.disabled):active:focus,
.btn-outline-gray:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-gray.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(128, 145, 167, 0.5);
}

.btn-outline-light {
  color: #e5e9f2;
  border-color: #e5e9f2;
}

.btn-outline-light:hover {
  color: #101924;
  background-color: #e5e9f2;
  border-color: #e5e9f2;
}

.btn-outline-light:focus,
.btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(229, 233, 242, 0.5);
}

.btn-outline-light.disabled,
.btn-outline-light:disabled {
  color: #e5e9f2;
  background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled):active,
.btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle {
  color: #101924;
  background-color: #e5e9f2;
  border-color: #e5e9f2;
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(229, 233, 242, 0.5);
}

.btn-outline-lighter {
  color: #f5f6fa;
  border-color: #f5f6fa;
}

.btn-outline-lighter:hover {
  color: #101924;
  background-color: #f5f6fa;
  border-color: #f5f6fa;
}

.btn-outline-lighter:focus,
.btn-outline-lighter.focus {
  box-shadow: 0 0 0 0.2rem rgba(245, 246, 250, 0.5);
}

.btn-outline-lighter.disabled,
.btn-outline-lighter:disabled {
  color: #f5f6fa;
  background-color: transparent;
}

.btn-outline-lighter:not(:disabled):not(.disabled):active,
.btn-outline-lighter:not(:disabled):not(.disabled).active,
.show > .btn-outline-lighter.dropdown-toggle {
  color: #101924;
  background-color: #f5f6fa;
  border-color: #f5f6fa;
}

.btn-outline-lighter:not(:disabled):not(.disabled):active:focus,
.btn-outline-lighter:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-lighter.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(245, 246, 250, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #798bff;
  text-decoration: none;
}

.btn-link:hover {
  color: #465fff;
  text-decoration: underline;
}

.btn-link:focus,
.btn-link.focus {
  text-decoration: underline;
}

.btn-link:disabled,
.btn-link.disabled {
  color: #f5f6fa;
  pointer-events: none;
}

.btn-lg,
.btn-group-lg > .btn {
  padding: 0.6875rem 1.5rem;
  font-size: 0.9375rem;
  line-height: 1.25rem;
  border-radius: 5px;
}

.btn-sm,
.btn-group-sm > .btn {
  padding: 0.25rem 0.75rem;
  font-size: 0.75rem;
  line-height: 1.25rem;
  border-radius: 3px;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type='submit'].btn-block,
input[type='reset'].btn-block,
input[type='button'].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: '';
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  text-align: left;
  min-width: 180px;
  padding: 0 0;
  margin: 0.125rem 0 0;
  font-size: 0.8125rem;
  color: #526484;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e5e9f2;
  border-radius: 4px;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1540px) {
  .dropdown-menu-xxl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xxl-right {
    right: 0;
    left: auto;
  }
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: '';
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  margin-top: 0;
  right: auto;
  left: 100%;
  margin-left: 0.125rem;
}

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: '';
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  margin-top: 0;
  right: 100%;
  left: auto;
  margin-right: 0.125rem;
}

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: '';
}

.dropleft .dropdown-toggle::after {
  display: none;
}

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: '';
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^='top'],
.dropdown-menu[x-placement^='right'],
.dropdown-menu[x-placement^='bottom'],
.dropdown-menu[x-placement^='left'] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 12px 0;
  overflow: hidden;
  border-top: 1px solid #e5e9f2;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 8px 14px;
  clear: both;
  font-weight: 400;
  color: #364a63;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:first-child {
  border-top-left-radius: calc(4px - 1px);
  border-top-right-radius: calc(4px - 1px);
}

.dropdown-item:last-child {
  border-bottom-right-radius: calc(4px - 1px);
  border-bottom-left-radius: calc(4px - 1px);
}

.dropdown-item:hover,
.dropdown-item:focus {
  color: #6576ff;
  text-decoration: none;
  background-color: #ebeef2;
}

.dropdown-item.active,
.dropdown-item:active {
  color: #6576ff;
  text-decoration: none;
  background-color: #dbdfea;
}

.dropdown-item.disabled,
.dropdown-item:disabled {
  color: #ebeef2;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0 14px;
  margin-bottom: 0;
  font-size: 0.75rem;
  color: #8091a7;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 8px 14px;
  color: #364a63;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}

.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}

.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.84375rem;
  padding-left: 0.84375rem;
}

.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split,
.btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}

.btn-lg + .dropdown-toggle-split,
.btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 1.125rem;
  padding-left: 1.125rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}

.btn-group-toggle > .btn input[type='radio'],
.btn-group-toggle > .btn input[type='checkbox'],
.btn-group-toggle > .btn-group > .btn input[type='radio'],
.btn-group-toggle > .btn-group > .btn input[type='checkbox'] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}

.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}

.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}

.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}

.input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group > .custom-file {
  display: flex;
  align-items: center;
}

.input-group > .custom-file:not(:last-child) .custom-file-label,
.input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}

.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}

.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}

.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.4375rem 1rem;
  margin-bottom: 0;
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1.25rem;
  color: #3c4d62;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #dbdfea;
  border-radius: 4px;
}

.input-group-text input[type='radio'],
.input-group-text input[type='checkbox'] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(2.625rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.6875rem 1rem;
  font-size: 0.9375rem;
  line-height: 1.25rem;
  border-radius: 5px;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.75rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 1rem;
  font-size: 0.75rem;
  line-height: 1.25rem;
  border-radius: 3px;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 2rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group
  > .input-group-append:last-child
  > .btn:not(:last-child):not(.dropdown-toggle),
.input-group
  > .input-group-append:last-child
  > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group
  > .input-group-prepend:first-child
  > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.44375rem;
  padding-left: 2.25rem;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1.5rem;
  height: 1.47187rem;
  opacity: 0;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #6576ff;
  background-color: #6576ff;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 3px rgba(101, 118, 255, 0.1);
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #6576ff;
}

.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #6576ff;
  border-color: #6576ff;
}

.custom-control-input[disabled] ~ .custom-control-label,
.custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}

.custom-control-input[disabled] ~ .custom-control-label::before,
.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #f5f6fa;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}

.custom-control-label::before {
  position: absolute;
  top: -0.02813rem;
  left: -2.25rem;
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  pointer-events: none;
  content: '';
  background-color: #fff;
  border: #dbdfea solid 2px;
}

.custom-control-label::after {
  position: absolute;
  top: -0.02813rem;
  left: -2.25rem;
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  content: '';
  background: no-repeat 50% / 50% 50%;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 4px;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}

.custom-checkbox
  .custom-control-input:indeterminate
  ~ .custom-control-label::before {
  border-color: #559bfb;
  background-color: #559bfb;
}

.custom-checkbox
  .custom-control-input:indeterminate
  ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}

.custom-checkbox
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  background-color: rgba(101, 118, 255, 0.8);
}

.custom-checkbox
  .custom-control-input:disabled:indeterminate
  ~ .custom-control-label::before {
  background-color: rgba(101, 118, 255, 0.8);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.custom-radio
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  background-color: rgba(101, 118, 255, 0.8);
}

.custom-switch {
  padding-left: 3.75rem;
}

.custom-switch .custom-control-label::before {
  left: -3.75rem;
  width: 3rem;
  pointer-events: all;
  border-radius: 1.5rem;
}

.custom-switch .custom-control-label::after {
  top: calc(-0.02813rem + 4px);
  left: calc(-3.75rem + 4px);
  width: 1rem;
  height: 1rem;
  background-color: #dbdfea;
  border-radius: 1.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  transform: translateX(1.5rem);
}

.custom-switch
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  background-color: rgba(101, 118, 255, 0.8);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(2.125rem + 2px);
  padding: 0.4375rem 2rem 0.4375rem 1rem;
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1.25rem;
  color: #3c4d62;
  vertical-align: middle;
  background: #fff
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
    no-repeat right 1rem center/8px 10px;
  border: 1px solid #dbdfea;
  border-radius: 4px;
  appearance: none;
}

.custom-select:focus {
  border-color: #6576ff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(85, 155, 251, 0.25);
}

.custom-select:focus::-ms-value {
  color: #3c4d62;
  background-color: #fff;
}

.custom-select[multiple],
.custom-select[size]:not([size='1']) {
  height: auto;
  padding-right: 1rem;
  background-image: none;
}

.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}

.custom-select::-ms-expand {
  display: none;
}

.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #3c4d62;
}

.custom-select-sm {
  height: calc(1.75rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 1rem;
  font-size: 0.75rem;
}

.custom-select-lg {
  height: calc(2.625rem + 2px);
  padding-top: 0.6875rem;
  padding-bottom: 0.6875rem;
  padding-left: 1rem;
  font-size: 0.9375rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(2.125rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(2.125rem + 2px);
  margin: 0;
  opacity: 0;
}

.custom-file-input:focus ~ .custom-file-label {
  border-color: #6576ff;
  box-shadow: 0 0 0 3px rgba(101, 118, 255, 0.1);
}

.custom-file-input[disabled] ~ .custom-file-label,
.custom-file-input:disabled ~ .custom-file-label {
  background-color: #f5f6fa;
}

.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: 'Browse';
}

.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(2.125rem + 2px);
  padding: 0.4375rem 1rem;
  font-weight: 400;
  line-height: 1.25rem;
  color: #3c4d62;
  background-color: #fff;
  border: 1px solid #dbdfea;
  border-radius: 4px;
}

.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: 2.125rem;
  padding: 0.4375rem 1rem;
  line-height: 1.25rem;
  color: #3c4d62;
  content: 'Browse';
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 4px 4px 0;
}

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}

.custom-range:focus {
  outline: none;
}

.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #f5f6fa, 0 0 0 3px rgba(101, 118, 255, 0.1);
}

.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #f5f6fa, 0 0 0 3px rgba(101, 118, 255, 0.1);
}

.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #f5f6fa, 0 0 0 3px rgba(101, 118, 255, 0.1);
}

.custom-range::-moz-focus-outer {
  border: 0;
}

.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #559bfb;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}

.custom-range::-webkit-slider-thumb:active {
  background-color: white;
}

.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #559bfb;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}

.custom-range::-moz-range-thumb:active {
  background-color: white;
}

.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #559bfb;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}

.custom-range::-ms-thumb:active {
  background-color: white;
}

.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}

.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}

.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}

.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}

.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}

.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.custom-range:disabled::-moz-range-track {
  cursor: default;
}

.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}

.nav-link:hover,
.nav-link:focus {
  text-decoration: none;
}

.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}

.nav-tabs .nav-item {
  margin-bottom: -1px;
}

.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}

.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #f5f6fa;
  border-color: #dee2e6 #dee2e6 #f5f6fa;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 4px;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #559bfb;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}

.navbar .container,
.navbar .container-fluid,
.navbar .container-sm,
.navbar .container-md,
.navbar .container-lg,
.navbar .container-xl,
.navbar .container-xxl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}

.navbar-brand:hover,
.navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 4px;
}

.navbar-toggler:hover,
.navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: '';
  background: no-repeat center center;
  background-size: 100% 100%;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-sm,
  .navbar-expand-sm > .container-md,
  .navbar-expand-sm > .container-lg,
  .navbar-expand-sm > .container-xl,
  .navbar-expand-sm > .container-xxl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid,
  .navbar-expand-sm > .container-sm,
  .navbar-expand-sm > .container-md,
  .navbar-expand-sm > .container-lg,
  .navbar-expand-sm > .container-xl,
  .navbar-expand-sm > .container-xxl {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-sm,
  .navbar-expand-md > .container-md,
  .navbar-expand-md > .container-lg,
  .navbar-expand-md > .container-xl,
  .navbar-expand-md > .container-xxl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid,
  .navbar-expand-md > .container-sm,
  .navbar-expand-md > .container-md,
  .navbar-expand-md > .container-lg,
  .navbar-expand-md > .container-xl,
  .navbar-expand-md > .container-xxl {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-xl,
  .navbar-expand-lg > .container-xxl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid,
  .navbar-expand-lg > .container-sm,
  .navbar-expand-lg > .container-md,
  .navbar-expand-lg > .container-lg,
  .navbar-expand-lg > .container-xl,
  .navbar-expand-lg > .container-xxl {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-sm,
  .navbar-expand-xl > .container-md,
  .navbar-expand-xl > .container-lg,
  .navbar-expand-xl > .container-xl,
  .navbar-expand-xl > .container-xxl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid,
  .navbar-expand-xl > .container-sm,
  .navbar-expand-xl > .container-md,
  .navbar-expand-xl > .container-lg,
  .navbar-expand-xl > .container-xl,
  .navbar-expand-xl > .container-xxl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}

@media (max-width: 1539.98px) {
  .navbar-expand-xxl > .container,
  .navbar-expand-xxl > .container-fluid,
  .navbar-expand-xxl > .container-sm,
  .navbar-expand-xxl > .container-md,
  .navbar-expand-xxl > .container-lg,
  .navbar-expand-xxl > .container-xl,
  .navbar-expand-xxl > .container-xxl {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1540px) {
  .navbar-expand-xxl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xxl > .container,
  .navbar-expand-xxl > .container-fluid,
  .navbar-expand-xxl > .container-sm,
  .navbar-expand-xxl > .container-md,
  .navbar-expand-xxl > .container-lg,
  .navbar-expand-xxl > .container-xl,
  .navbar-expand-xxl > .container-xxl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl,
.navbar-expand > .container-xxl {
  padding-right: 0;
  padding-left: 0;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl,
.navbar-expand > .container-xxl {
  flex-wrap: nowrap;
}

.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-brand:hover,
.navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}

.navbar-dark .navbar-brand:hover,
.navbar-dark .navbar-brand:focus {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-text a {
  color: #fff;
}

.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid rgba(0, 0, 0, 0.125);
  border-radius: 4px;
}

.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.07);
  border-bottom: 0 solid rgba(0, 0, 0, 0.125);
}

.card-header:first-child {
  border-radius: 3px 3px 0 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.07);
  border-top: 0 solid rgba(0, 0, 0, 0.125);
}

.card-footer:last-child {
  border-radius: 0 0 3px 3px;
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: 3px;
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.card-deck .card {
  margin-bottom: 14px;
}

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -14px;
    margin-left: -14px;
  }
  .card-deck .card {
    flex: 1 0 0%;
    margin-right: 14px;
    margin-bottom: 0;
    margin-left: 14px;
  }
}

.card-group > .card {
  margin-bottom: 14px;
}

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion {
  overflow-anchor: none;
}

.accordion > .card {
  overflow: hidden;
}

.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: 0;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.25rem 0;
  margin-bottom: 0;
  font-size: 0.6875rem;
  list-style: none;
  background-color: transparent;
  border-radius: 4px;
}

.breadcrumb-item {
  display: flex;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #8091a7;
  content: '/';
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}

.breadcrumb-item.active {
  color: #b7c2d0;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 4px;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5625rem 0.625rem;
  margin-left: -1px;
  line-height: 1rem;
  color: #526484;
  background-color: #fff;
  border: 1px solid #e5e9f2;
}

.page-link:hover {
  z-index: 2;
  color: #465fff;
  text-decoration: none;
  background-color: #ebeef2;
  border-color: #e5e9f2;
}

.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: none;
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.page-item:last-child .page-link {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #6576ff;
  border-color: #6576ff;
}

.page-item.disabled .page-link {
  color: #dbdfea;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #e5e9f2;
}

.pagination-lg .page-link {
  padding: 0.5625rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.pagination-sm .page-link {
  padding: 0.4375rem 0.5rem;
  font-size: 0.75rem;
  line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.badge {
  display: inline-block;
  padding: 0 0.375rem;
  font-size: 0.675rem;
  font-weight: 500;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 3px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}

a.badge:hover,
a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #6576ff;
}

a.badge-primary:hover,
a.badge-primary:focus {
  color: #fff;
  background-color: #3249ff;
}

a.badge-primary:focus,
a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(101, 118, 255, 0.5);
}

.badge-secondary {
  color: #fff;
  background-color: #364a63;
}

a.badge-secondary:hover,
a.badge-secondary:focus {
  color: #fff;
  background-color: #243142;
}

a.badge-secondary:focus,
a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(54, 74, 99, 0.5);
}

.badge-success {
  color: #fff;
  background-color: #1ee0ac;
}

a.badge-success:hover,
a.badge-success:focus {
  color: #fff;
  background-color: #18b389;
}

a.badge-success:focus,
a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(30, 224, 172, 0.5);
}

.badge-info {
  color: #fff;
  background-color: #09c2de;
}

a.badge-info:hover,
a.badge-info:focus {
  color: #fff;
  background-color: #0797ad;
}

a.badge-info:focus,
a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(9, 194, 222, 0.5);
}

.badge-warning {
  color: #fff;
  background-color: #f4bd0e;
}

a.badge-warning:hover,
a.badge-warning:focus {
  color: #fff;
  background-color: #c69909;
}

a.badge-warning:focus,
a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(244, 189, 14, 0.5);
}

.badge-danger {
  color: #fff;
  background-color: #e85347;
}

a.badge-danger:hover,
a.badge-danger:focus {
  color: #fff;
  background-color: #e02b1c;
}

a.badge-danger:focus,
a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(232, 83, 71, 0.5);
}

.badge-dark {
  color: #fff;
  background-color: #1c2b46;
}

a.badge-dark:hover,
a.badge-dark:focus {
  color: #fff;
  background-color: #0d1522;
}

a.badge-dark:focus,
a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(28, 43, 70, 0.5);
}

.badge-gray {
  color: #fff;
  background-color: #8091a7;
}

a.badge-gray:hover,
a.badge-gray:focus {
  color: #fff;
  background-color: #647790;
}

a.badge-gray:focus,
a.badge-gray.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(128, 145, 167, 0.5);
}

.badge-light {
  color: #101924;
  background-color: #e5e9f2;
}

a.badge-light:hover,
a.badge-light:focus {
  color: #101924;
  background-color: #c3cce1;
}

a.badge-light:focus,
a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(229, 233, 242, 0.5);
}

.badge-lighter {
  color: #101924;
  background-color: #f5f6fa;
}

a.badge-lighter:hover,
a.badge-lighter:focus {
  color: #101924;
  background-color: #d3d7e9;
}

a.badge-lighter:focus,
a.badge-lighter.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(245, 246, 250, 0.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 5px;
}

@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 1rem 1.25rem;
  margin-bottom: 2rem;
  border: 1px solid transparent;
  border-radius: 4px;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}

.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 1rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #5563d6;
  background-color: #edefff;
  border-color: #c8ceff;
}

.alert-primary hr {
  border-top-color: #afb7ff;
}

.alert-primary .alert-link {
  color: #3041c8;
}

.alert-secondary {
  color: #2d3e53;
  background-color: #e7e9ec;
  border-color: #b7bec7;
}

.alert-secondary hr {
  border-top-color: #a9b1bc;
}

.alert-secondary .alert-link {
  color: #1b2532;
}

.alert-success {
  color: #19bc90;
  background-color: #e4fbf5;
  border-color: #aef4e1;
}

.alert-success hr {
  border-top-color: #98f1d9;
}

.alert-success .alert-link {
  color: #138f6e;
}

.alert-info {
  color: #08a3ba;
  background-color: #e1f8fb;
  border-color: #a6e9f3;
}

.alert-info hr {
  border-top-color: #90e3f0;
}

.alert-info .alert-link {
  color: #067889;
}

.alert-warning {
  color: #cd9f0c;
  background-color: #fef7e2;
  border-color: #fbe7a8;
}

.alert-warning hr {
  border-top-color: #fae090;
}

.alert-warning .alert-link {
  color: #9d7a09;
}

.alert-danger {
  color: #c3463c;
  background-color: #fceae9;
  border-color: #f7c1bd;
}

.alert-danger hr {
  border-top-color: #f4aca6;
}

.alert-danger .alert-link {
  color: #9c3830;
}

.alert-dark {
  color: #18243b;
  background-color: #e4e6e9;
  border-color: #adb3bc;
}

.alert-dark hr {
  border-top-color: #9fa6b1;
}

.alert-dark .alert-link {
  color: #090e17;
}

.alert-gray {
  color: #6c7a8c;
  background-color: #f0f2f4;
  border-color: #d1d7df;
}

.alert-gray hr {
  border-top-color: #c2cad5;
}

.alert-gray .alert-link {
  color: #56616f;
}

.alert-light {
  color: #c0c4cb;
  background-color: #fcfcfd;
  border-color: #f6f7fa;
}

.alert-light hr {
  border-top-color: #e6e8f1;
}

.alert-light .alert-link {
  color: #a4aab4;
}

.alert-lighter {
  color: #cecfd2;
  background-color: #fefefe;
  border-color: #fbfcfd;
}

.alert-lighter hr {
  border-top-color: #eaeff5;
}

.alert-lighter .alert-link {
  color: #b3b5ba;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 0.5rem 0;
  }
  to {
    background-position: 0 0;
  }
}

.progress {
  display: flex;
  height: 0.5rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #f5f6fa;
  border-radius: 2px;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #6576ff;
  transition: width 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-size: 0.5rem 0.5rem;
}

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 4px;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}

.list-group-item-action:hover,
.list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}

.list-group-item-action:active {
  color: #526484;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}

.list-group-item.disabled,
.list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #559bfb;
  border-color: #559bfb;
}

.list-group-item + .list-group-item {
  border-top-width: 0;
}

.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}

.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 4px;
  border-top-right-radius: 0;
}

.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 4px;
  border-bottom-left-radius: 0;
}

.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}

.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 4px;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 4px;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 4px;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 4px;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 4px;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 4px;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 4px;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 4px;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 1540px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child {
    border-bottom-left-radius: 4px;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child {
    border-top-right-radius: 4px;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

.list-group-flush {
  border-radius: 0;
}

.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}

.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #353d85;
  background-color: #d4d9ff;
}

.list-group-item-primary.list-group-item-action:hover,
.list-group-item-primary.list-group-item-action:focus {
  color: #353d85;
  background-color: #bbc2ff;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #353d85;
  border-color: #353d85;
}

.list-group-item-secondary {
  color: #1c2633;
  background-color: #c7ccd3;
}

.list-group-item-secondary.list-group-item-action:hover,
.list-group-item-secondary.list-group-item-action:focus {
  color: #1c2633;
  background-color: #b9bfc8;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #1c2633;
  border-color: #1c2633;
}

.list-group-item-success {
  color: #107459;
  background-color: #c0f6e8;
}

.list-group-item-success.list-group-item-action:hover,
.list-group-item-success.list-group-item-action:focus {
  color: #107459;
  background-color: #aaf3e0;
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #107459;
  border-color: #107459;
}

.list-group-item-info {
  color: #056573;
  background-color: #baeef6;
}

.list-group-item-info.list-group-item-action:hover,
.list-group-item-info.list-group-item-action:focus {
  color: #056573;
  background-color: #a3e8f3;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #056573;
  border-color: #056573;
}

.list-group-item-warning {
  color: #7f6207;
  background-color: #fcedbc;
}

.list-group-item-warning.list-group-item-action:hover,
.list-group-item-warning.list-group-item-action:focus {
  color: #7f6207;
  background-color: #fbe6a4;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #7f6207;
  border-color: #7f6207;
}

.list-group-item-danger {
  color: #792b25;
  background-color: #f9cfcb;
}

.list-group-item-danger.list-group-item-action:hover,
.list-group-item-danger.list-group-item-action:focus {
  color: #792b25;
  background-color: #f6bab4;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #792b25;
  border-color: #792b25;
}

.list-group-item-dark {
  color: #0f1624;
  background-color: #bfc4cb;
}

.list-group-item-dark.list-group-item-action:hover,
.list-group-item-dark.list-group-item-action:focus {
  color: #0f1624;
  background-color: #b1b7c0;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #0f1624;
  border-color: #0f1624;
}

.list-group-item-gray {
  color: #434b57;
  background-color: #dbe0e6;
}

.list-group-item-gray.list-group-item-action:hover,
.list-group-item-gray.list-group-item-action:focus {
  color: #434b57;
  background-color: #ccd3dc;
}

.list-group-item-gray.list-group-item-action.active {
  color: #fff;
  background-color: #434b57;
  border-color: #434b57;
}

.list-group-item-light {
  color: #77797e;
  background-color: #f8f9fb;
}

.list-group-item-light.list-group-item-action:hover,
.list-group-item-light.list-group-item-action:focus {
  color: #77797e;
  background-color: #e8ebf2;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #77797e;
  border-color: #77797e;
}

.list-group-item-lighter {
  color: #7f8082;
  background-color: #fcfcfe;
}

.list-group-item-lighter.list-group-item-action:hover,
.list-group-item-lighter.list-group-item-action:focus {
  color: #7f8082;
  background-color: #e9e9f8;
}

.list-group-item-lighter.list-group-item-action.active {
  color: #fff;
  background-color: #7f8082;
  border-color: #7f8082;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}

.close:hover {
  color: #000;
  text-decoration: none;
}

.close:not(:disabled):not(.disabled):hover,
.close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem;
}

.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}

.toast.showing {
  opacity: 1;
}

.toast.show {
  display: block;
  opacity: 1;
}

.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -30px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none !important;
}

.modal.modal-static .modal-dialog {
  transform: scale(0.95);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}

.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: min-content;
  content: '';
}

.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}

.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 0 solid rgba(0, 0, 0, 0);
  border-radius: 5px;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #364a63;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1.25rem;
  border-bottom: 1px solid #dbdfea;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.modal-header .close {
  padding: 1rem 1.25rem;
  margin: -1rem -1.25rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1.25rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #dbdfea;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 520px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: min-content;
  }
  .modal-sm {
    max-width: 360px;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 720px;
  }
  .modal-xxl {
    max-width: 900px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 980px;
  }
  .modal-xxl {
    max-width: 1200px;
  }
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: Roboto, sans-serif, 'Helvetica Neue', Arial, 'Noto Sans',
    sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.65;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.8125rem;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: 0.9;
}

.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}

.tooltip .arrow::before {
  position: absolute;
  content: '';
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top,
.bs-tooltip-auto[x-placement^='top'] {
  padding: 0.4rem 0;
}

.bs-tooltip-top .arrow,
.bs-tooltip-auto[x-placement^='top'] .arrow {
  bottom: 0;
}

.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^='top'] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #1f2b3a;
}

.bs-tooltip-right,
.bs-tooltip-auto[x-placement^='right'] {
  padding: 0 0.4rem;
}

.bs-tooltip-right .arrow,
.bs-tooltip-auto[x-placement^='right'] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^='right'] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #1f2b3a;
}

.bs-tooltip-bottom,
.bs-tooltip-auto[x-placement^='bottom'] {
  padding: 0.4rem 0;
}

.bs-tooltip-bottom .arrow,
.bs-tooltip-auto[x-placement^='bottom'] .arrow {
  top: 0;
}

.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^='bottom'] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #1f2b3a;
}

.bs-tooltip-left,
.bs-tooltip-auto[x-placement^='left'] {
  padding: 0 0.4rem;
}

.bs-tooltip-left .arrow,
.bs-tooltip-auto[x-placement^='left'] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^='left'] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #1f2b3a;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.75rem;
  color: #fff;
  text-align: center;
  background-color: #1f2b3a;
  border-radius: 3px;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: Roboto, sans-serif, 'Helvetica Neue', Arial, 'Noto Sans',
    sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.65;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 5px;
}

.popover .arrow::before,
.popover .arrow::after {
  position: absolute;
  display: block;
  content: '';
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top,
.bs-popover-auto[x-placement^='top'] {
  margin-bottom: 0.5rem;
}

.bs-popover-top > .arrow,
.bs-popover-auto[x-placement^='top'] > .arrow {
  bottom: calc(-0.5rem - 1px);
}

.bs-popover-top > .arrow::before,
.bs-popover-auto[x-placement^='top'] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-top > .arrow::after,
.bs-popover-auto[x-placement^='top'] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-right,
.bs-popover-auto[x-placement^='right'] {
  margin-left: 0.5rem;
}

.bs-popover-right > .arrow,
.bs-popover-auto[x-placement^='right'] > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 5px 0;
}

.bs-popover-right > .arrow::before,
.bs-popover-auto[x-placement^='right'] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-right > .arrow::after,
.bs-popover-auto[x-placement^='right'] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom,
.bs-popover-auto[x-placement^='bottom'] {
  margin-top: 0.5rem;
}

.bs-popover-bottom > .arrow,
.bs-popover-auto[x-placement^='bottom'] > .arrow {
  top: calc(-0.5rem - 1px);
}

.bs-popover-bottom > .arrow::before,
.bs-popover-auto[x-placement^='bottom'] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-bottom > .arrow::after,
.bs-popover-auto[x-placement^='bottom'] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}

.bs-popover-bottom .popover-header::before,
.bs-popover-auto[x-placement^='bottom'] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: '';
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left,
.bs-popover-auto[x-placement^='left'] {
  margin-right: 0.5rem;
}

.bs-popover-left > .arrow,
.bs-popover-auto[x-placement^='left'] > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 5px 0;
}

.bs-popover-left > .arrow::before,
.bs-popover-auto[x-placement^='left'] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-left > .arrow::after,
.bs-popover-auto[x-placement^='left'] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #526484;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-inner::after {
  display: block;
  clear: both;
  content: '';
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}

.carousel-control-prev:hover,
.carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border 0.75s linear infinite;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}

.spinner-grow {
  display: block;
  width: 2rem;
  height: 2rem;
  text-align: center;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow 0.75s linear infinite;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #6576ff !important;
}

a.bg-primary:hover,
a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #3249ff !important;
}

.bg-secondary {
  background-color: #364a63 !important;
}

a.bg-secondary:hover,
a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #243142 !important;
}

.bg-success {
  background-color: #1ee0ac !important;
}

a.bg-success:hover,
a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #18b389 !important;
}

.bg-info {
  background-color: #09c2de !important;
}

a.bg-info:hover,
a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #0797ad !important;
}

.bg-warning {
  background-color: #f4bd0e !important;
}

a.bg-warning:hover,
a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #c69909 !important;
}

.bg-danger {
  background-color: #e85347 !important;
}

a.bg-danger:hover,
a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #e02b1c !important;
}

.bg-dark {
  background-color: #1c2b46 !important;
}

a.bg-dark:hover,
a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #0d1522 !important;
}

.bg-gray {
  background-color: #8091a7 !important;
}

a.bg-gray:hover,
a.bg-gray:focus,
button.bg-gray:hover,
button.bg-gray:focus {
  background-color: #647790 !important;
}

.bg-light {
  background-color: #e5e9f2 !important;
}

a.bg-light:hover,
a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #c3cce1 !important;
}

.bg-lighter {
  background-color: #f5f6fa !important;
}

a.bg-lighter:hover,
a.bg-lighter:focus,
button.bg-lighter:hover,
button.bg-lighter:focus {
  background-color: #d3d7e9 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid #dbdfea !important;
}

.border-top {
  border-top: 1px solid #dbdfea !important;
}

.border-right {
  border-right: 1px solid #dbdfea !important;
}

.border-bottom {
  border-bottom: 1px solid #dbdfea !important;
}

.border-left {
  border-left: 1px solid #dbdfea !important;
}

.border-0,
.no-bdr {
  border: 0 !important;
}

.border-top-0,
.no-bdr-t,
.no-bdr-y {
  border-top: 0 !important;
}

.border-right-0,
.no-bdr-r,
.no-bdr-x {
  border-right: 0 !important;
}

.border-bottom-0,
.no-bdr-b,
.no-bdr-y {
  border-bottom: 0 !important;
}

.border-left-0,
.no-bdr-l,
.no-bdr-x {
  border-left: 0 !important;
}

.border-primary {
  border-color: #6576ff !important;
}

.border-secondary {
  border-color: #364a63 !important;
}

.border-success {
  border-color: #1ee0ac !important;
}

.border-info {
  border-color: #09c2de !important;
}

.border-warning {
  border-color: #f4bd0e !important;
}

.border-danger {
  border-color: #e85347 !important;
}

.border-dark {
  border-color: #1c2b46 !important;
}

.border-gray {
  border-color: #8091a7 !important;
}

.border-light {
  border-color: #e5e9f2 !important;
}

.border-lighter {
  border-color: #f5f6fa !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 3px !important;
}

.rounded {
  border-radius: 4px !important;
}

.rounded-top {
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
}

.rounded-right {
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}

.rounded-bottom {
  border-bottom-right-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}

.rounded-left {
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}

.rounded-lg {
  border-radius: 5px !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 1.5rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: '';
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex,
.align-start,
.align-end,
.align-center,
.justify-start,
.justify-end,
.justify-center,
.justify-between,
.justify-around,
.between-start,
.between-center,
.center,
.stretch {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1540px) {
  .d-xxl-none {
    display: none !important;
  }
  .d-xxl-inline {
    display: inline !important;
  }
  .d-xxl-inline-block {
    display: inline-block !important;
  }
  .d-xxl-block {
    display: block !important;
  }
  .d-xxl-table {
    display: table !important;
  }
  .d-xxl-table-row {
    display: table-row !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
  .d-xxl-flex {
    display: flex !important;
  }
  .d-xxl-inline-flex {
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive::before {
  display: block;
  content: '';
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start,
.justify-start {
  justify-content: flex-start !important;
}

.justify-content-end,
.justify-end {
  justify-content: flex-end !important;
}

.justify-content-center,
.justify-center,
.center {
  justify-content: center !important;
}

.justify-content-between,
.justify-between,
.between-start,
.between-center {
  justify-content: space-between !important;
}

.justify-content-around,
.justify-around {
  justify-content: space-around !important;
}

.align-items-start,
.align-start,
.between-start {
  align-items: flex-start !important;
}

.align-items-end,
.align-end {
  align-items: flex-end !important;
}

.align-items-center,
.align-center,
.between-center,
.center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch,
.stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1540px) {
  .flex-xxl-row {
    flex-direction: row !important;
  }
  .flex-xxl-column {
    flex-direction: column !important;
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xxl-center {
    justify-content: center !important;
  }
  .justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .align-items-xxl-start {
    align-items: flex-start !important;
  }
  .align-items-xxl-end {
    align-items: flex-end !important;
  }
  .align-items-xxl-center {
    align-items: center !important;
  }
  .align-items-xxl-baseline {
    align-items: baseline !important;
  }
  .align-items-xxl-stretch {
    align-items: stretch !important;
  }
  .align-content-xxl-start {
    align-content: flex-start !important;
  }
  .align-content-xxl-end {
    align-content: flex-end !important;
  }
  .align-content-xxl-center {
    align-content: center !important;
  }
  .align-content-xxl-between {
    align-content: space-between !important;
  }
  .align-content-xxl-around {
    align-content: space-around !important;
  }
  .align-content-xxl-stretch {
    align-content: stretch !important;
  }
  .align-self-xxl-auto {
    align-self: auto !important;
  }
  .align-self-xxl-start {
    align-self: flex-start !important;
  }
  .align-self-xxl-end {
    align-self: flex-end !important;
  }
  .align-self-xxl-center {
    align-self: center !important;
  }
  .align-self-xxl-baseline {
    align-self: baseline !important;
  }
  .align-self-xxl-stretch {
    align-self: stretch !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}

@media (min-width: 1540px) {
  .float-xxl-left {
    float: left !important;
  }
  .float-xxl-right {
    float: right !important;
  }
  .float-xxl-none {
    float: none !important;
  }
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative,
.pos-rel {
  position: relative !important;
}

.position-absolute,
.pos-abs {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(43, 55, 72, 0.15) !important;
}

.shadow {
  box-shadow: 0 3px 12px 1px rgba(43, 55, 72, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(43, 55, 72, 0.25) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-50 {
  max-width: 50% !important;
}

.mw-75 {
  max-width: 75% !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.375rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.375rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.375rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.375rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.375rem !important;
}

.m-2 {
  margin: 0.75rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.75rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.75rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.75rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.75rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 2.75rem !important;
}

.mt-5,
.my-5 {
  margin-top: 2.75rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 2.75rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 2.75rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 2.75rem !important;
}

.m-gs {
  margin: 28px !important;
}

.mt-gs,
.my-gs {
  margin-top: 28px !important;
}

.mr-gs,
.mx-gs {
  margin-right: 28px !important;
}

.mb-gs,
.my-gs {
  margin-bottom: 28px !important;
}

.ml-gs,
.mx-gs {
  margin-left: 28px !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.375rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.375rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.375rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.375rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.375rem !important;
}

.p-2 {
  padding: 0.75rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.75rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.75rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.75rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.75rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 2.75rem !important;
}

.pt-5,
.py-5 {
  padding-top: 2.75rem !important;
}

.pr-5,
.px-5 {
  padding-right: 2.75rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 2.75rem !important;
}

.pl-5,
.px-5 {
  padding-left: 2.75rem !important;
}

.p-gs {
  padding: 28px !important;
}

.pt-gs,
.py-gs {
  padding-top: 28px !important;
}

.pr-gs,
.px-gs {
  padding-right: 28px !important;
}

.pb-gs,
.py-gs {
  padding-bottom: 28px !important;
}

.pl-gs,
.px-gs {
  padding-left: 28px !important;
}

.m-n1 {
  margin: -0.375rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.375rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.375rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.375rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.375rem !important;
}

.m-n2 {
  margin: -0.75rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.75rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.75rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.75rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.75rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -2.75rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -2.75rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -2.75rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -2.75rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -2.75rem !important;
}

.m-ngs {
  margin: -28px !important;
}

.mt-ngs,
.my-ngs {
  margin-top: -28px !important;
}

.mr-ngs,
.mx-ngs {
  margin-right: -28px !important;
}

.mb-ngs,
.my-ngs {
  margin-bottom: -28px !important;
}

.ml-ngs,
.mx-ngs {
  margin-left: -28px !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.375rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.375rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.375rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.375rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.375rem !important;
  }
  .m-sm-2 {
    margin: 0.75rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.75rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.75rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.75rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.75rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 2.75rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 2.75rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 2.75rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 2.75rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 2.75rem !important;
  }
  .m-sm-gs {
    margin: 28px !important;
  }
  .mt-sm-gs,
  .my-sm-gs {
    margin-top: 28px !important;
  }
  .mr-sm-gs,
  .mx-sm-gs {
    margin-right: 28px !important;
  }
  .mb-sm-gs,
  .my-sm-gs {
    margin-bottom: 28px !important;
  }
  .ml-sm-gs,
  .mx-sm-gs {
    margin-left: 28px !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.375rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.375rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.375rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.375rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.375rem !important;
  }
  .p-sm-2 {
    padding: 0.75rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.75rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.75rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.75rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.75rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 2.75rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 2.75rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 2.75rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 2.75rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 2.75rem !important;
  }
  .p-sm-gs {
    padding: 28px !important;
  }
  .pt-sm-gs,
  .py-sm-gs {
    padding-top: 28px !important;
  }
  .pr-sm-gs,
  .px-sm-gs {
    padding-right: 28px !important;
  }
  .pb-sm-gs,
  .py-sm-gs {
    padding-bottom: 28px !important;
  }
  .pl-sm-gs,
  .px-sm-gs {
    padding-left: 28px !important;
  }
  .m-sm-n1 {
    margin: -0.375rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.375rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.375rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.375rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.375rem !important;
  }
  .m-sm-n2 {
    margin: -0.75rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.75rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.75rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.75rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.75rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -2.75rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -2.75rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -2.75rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -2.75rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -2.75rem !important;
  }
  .m-sm-ngs {
    margin: -28px !important;
  }
  .mt-sm-ngs,
  .my-sm-ngs {
    margin-top: -28px !important;
  }
  .mr-sm-ngs,
  .mx-sm-ngs {
    margin-right: -28px !important;
  }
  .mb-sm-ngs,
  .my-sm-ngs {
    margin-bottom: -28px !important;
  }
  .ml-sm-ngs,
  .mx-sm-ngs {
    margin-left: -28px !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.375rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.375rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.375rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.375rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.375rem !important;
  }
  .m-md-2 {
    margin: 0.75rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.75rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.75rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.75rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.75rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 2.75rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 2.75rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 2.75rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 2.75rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 2.75rem !important;
  }
  .m-md-gs {
    margin: 28px !important;
  }
  .mt-md-gs,
  .my-md-gs {
    margin-top: 28px !important;
  }
  .mr-md-gs,
  .mx-md-gs {
    margin-right: 28px !important;
  }
  .mb-md-gs,
  .my-md-gs {
    margin-bottom: 28px !important;
  }
  .ml-md-gs,
  .mx-md-gs {
    margin-left: 28px !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.375rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.375rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.375rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.375rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.375rem !important;
  }
  .p-md-2 {
    padding: 0.75rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.75rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.75rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.75rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.75rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 2.75rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 2.75rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 2.75rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 2.75rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 2.75rem !important;
  }
  .p-md-gs {
    padding: 28px !important;
  }
  .pt-md-gs,
  .py-md-gs {
    padding-top: 28px !important;
  }
  .pr-md-gs,
  .px-md-gs {
    padding-right: 28px !important;
  }
  .pb-md-gs,
  .py-md-gs {
    padding-bottom: 28px !important;
  }
  .pl-md-gs,
  .px-md-gs {
    padding-left: 28px !important;
  }
  .m-md-n1 {
    margin: -0.375rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.375rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.375rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.375rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.375rem !important;
  }
  .m-md-n2 {
    margin: -0.75rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.75rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.75rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.75rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.75rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -2.75rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -2.75rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -2.75rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -2.75rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -2.75rem !important;
  }
  .m-md-ngs {
    margin: -28px !important;
  }
  .mt-md-ngs,
  .my-md-ngs {
    margin-top: -28px !important;
  }
  .mr-md-ngs,
  .mx-md-ngs {
    margin-right: -28px !important;
  }
  .mb-md-ngs,
  .my-md-ngs {
    margin-bottom: -28px !important;
  }
  .ml-md-ngs,
  .mx-md-ngs {
    margin-left: -28px !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.375rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.375rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.375rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.375rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.375rem !important;
  }
  .m-lg-2 {
    margin: 0.75rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.75rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.75rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.75rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.75rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 2.75rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 2.75rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 2.75rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 2.75rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 2.75rem !important;
  }
  .m-lg-gs {
    margin: 28px !important;
  }
  .mt-lg-gs,
  .my-lg-gs {
    margin-top: 28px !important;
  }
  .mr-lg-gs,
  .mx-lg-gs {
    margin-right: 28px !important;
  }
  .mb-lg-gs,
  .my-lg-gs {
    margin-bottom: 28px !important;
  }
  .ml-lg-gs,
  .mx-lg-gs {
    margin-left: 28px !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.375rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.375rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.375rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.375rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.375rem !important;
  }
  .p-lg-2 {
    padding: 0.75rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.75rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.75rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.75rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.75rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 2.75rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 2.75rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 2.75rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 2.75rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 2.75rem !important;
  }
  .p-lg-gs {
    padding: 28px !important;
  }
  .pt-lg-gs,
  .py-lg-gs {
    padding-top: 28px !important;
  }
  .pr-lg-gs,
  .px-lg-gs {
    padding-right: 28px !important;
  }
  .pb-lg-gs,
  .py-lg-gs {
    padding-bottom: 28px !important;
  }
  .pl-lg-gs,
  .px-lg-gs {
    padding-left: 28px !important;
  }
  .m-lg-n1 {
    margin: -0.375rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.375rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.375rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.375rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.375rem !important;
  }
  .m-lg-n2 {
    margin: -0.75rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.75rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.75rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.75rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.75rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -2.75rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -2.75rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -2.75rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -2.75rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -2.75rem !important;
  }
  .m-lg-ngs {
    margin: -28px !important;
  }
  .mt-lg-ngs,
  .my-lg-ngs {
    margin-top: -28px !important;
  }
  .mr-lg-ngs,
  .mx-lg-ngs {
    margin-right: -28px !important;
  }
  .mb-lg-ngs,
  .my-lg-ngs {
    margin-bottom: -28px !important;
  }
  .ml-lg-ngs,
  .mx-lg-ngs {
    margin-left: -28px !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.375rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.375rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.375rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.375rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.375rem !important;
  }
  .m-xl-2 {
    margin: 0.75rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.75rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.75rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.75rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.75rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 2.75rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 2.75rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 2.75rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 2.75rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 2.75rem !important;
  }
  .m-xl-gs {
    margin: 28px !important;
  }
  .mt-xl-gs,
  .my-xl-gs {
    margin-top: 28px !important;
  }
  .mr-xl-gs,
  .mx-xl-gs {
    margin-right: 28px !important;
  }
  .mb-xl-gs,
  .my-xl-gs {
    margin-bottom: 28px !important;
  }
  .ml-xl-gs,
  .mx-xl-gs {
    margin-left: 28px !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.375rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.375rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.375rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.375rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.375rem !important;
  }
  .p-xl-2 {
    padding: 0.75rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.75rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.75rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.75rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.75rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 2.75rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 2.75rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 2.75rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 2.75rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 2.75rem !important;
  }
  .p-xl-gs {
    padding: 28px !important;
  }
  .pt-xl-gs,
  .py-xl-gs {
    padding-top: 28px !important;
  }
  .pr-xl-gs,
  .px-xl-gs {
    padding-right: 28px !important;
  }
  .pb-xl-gs,
  .py-xl-gs {
    padding-bottom: 28px !important;
  }
  .pl-xl-gs,
  .px-xl-gs {
    padding-left: 28px !important;
  }
  .m-xl-n1 {
    margin: -0.375rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.375rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.375rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.375rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.375rem !important;
  }
  .m-xl-n2 {
    margin: -0.75rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.75rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.75rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.75rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.75rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -2.75rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -2.75rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -2.75rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -2.75rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -2.75rem !important;
  }
  .m-xl-ngs {
    margin: -28px !important;
  }
  .mt-xl-ngs,
  .my-xl-ngs {
    margin-top: -28px !important;
  }
  .mr-xl-ngs,
  .mx-xl-ngs {
    margin-right: -28px !important;
  }
  .mb-xl-ngs,
  .my-xl-ngs {
    margin-bottom: -28px !important;
  }
  .ml-xl-ngs,
  .mx-xl-ngs {
    margin-left: -28px !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1540px) {
  .m-xxl-0 {
    margin: 0 !important;
  }
  .mt-xxl-0,
  .my-xxl-0 {
    margin-top: 0 !important;
  }
  .mr-xxl-0,
  .mx-xxl-0 {
    margin-right: 0 !important;
  }
  .mb-xxl-0,
  .my-xxl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xxl-0,
  .mx-xxl-0 {
    margin-left: 0 !important;
  }
  .m-xxl-1 {
    margin: 0.375rem !important;
  }
  .mt-xxl-1,
  .my-xxl-1 {
    margin-top: 0.375rem !important;
  }
  .mr-xxl-1,
  .mx-xxl-1 {
    margin-right: 0.375rem !important;
  }
  .mb-xxl-1,
  .my-xxl-1 {
    margin-bottom: 0.375rem !important;
  }
  .ml-xxl-1,
  .mx-xxl-1 {
    margin-left: 0.375rem !important;
  }
  .m-xxl-2 {
    margin: 0.75rem !important;
  }
  .mt-xxl-2,
  .my-xxl-2 {
    margin-top: 0.75rem !important;
  }
  .mr-xxl-2,
  .mx-xxl-2 {
    margin-right: 0.75rem !important;
  }
  .mb-xxl-2,
  .my-xxl-2 {
    margin-bottom: 0.75rem !important;
  }
  .ml-xxl-2,
  .mx-xxl-2 {
    margin-left: 0.75rem !important;
  }
  .m-xxl-3 {
    margin: 1rem !important;
  }
  .mt-xxl-3,
  .my-xxl-3 {
    margin-top: 1rem !important;
  }
  .mr-xxl-3,
  .mx-xxl-3 {
    margin-right: 1rem !important;
  }
  .mb-xxl-3,
  .my-xxl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xxl-3,
  .mx-xxl-3 {
    margin-left: 1rem !important;
  }
  .m-xxl-4 {
    margin: 1.5rem !important;
  }
  .mt-xxl-4,
  .my-xxl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xxl-4,
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xxl-4,
  .my-xxl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xxl-4,
  .mx-xxl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xxl-5 {
    margin: 2.75rem !important;
  }
  .mt-xxl-5,
  .my-xxl-5 {
    margin-top: 2.75rem !important;
  }
  .mr-xxl-5,
  .mx-xxl-5 {
    margin-right: 2.75rem !important;
  }
  .mb-xxl-5,
  .my-xxl-5 {
    margin-bottom: 2.75rem !important;
  }
  .ml-xxl-5,
  .mx-xxl-5 {
    margin-left: 2.75rem !important;
  }
  .m-xxl-gs {
    margin: 28px !important;
  }
  .mt-xxl-gs,
  .my-xxl-gs {
    margin-top: 28px !important;
  }
  .mr-xxl-gs,
  .mx-xxl-gs {
    margin-right: 28px !important;
  }
  .mb-xxl-gs,
  .my-xxl-gs {
    margin-bottom: 28px !important;
  }
  .ml-xxl-gs,
  .mx-xxl-gs {
    margin-left: 28px !important;
  }
  .p-xxl-0 {
    padding: 0 !important;
  }
  .pt-xxl-0,
  .py-xxl-0 {
    padding-top: 0 !important;
  }
  .pr-xxl-0,
  .px-xxl-0 {
    padding-right: 0 !important;
  }
  .pb-xxl-0,
  .py-xxl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xxl-0,
  .px-xxl-0 {
    padding-left: 0 !important;
  }
  .p-xxl-1 {
    padding: 0.375rem !important;
  }
  .pt-xxl-1,
  .py-xxl-1 {
    padding-top: 0.375rem !important;
  }
  .pr-xxl-1,
  .px-xxl-1 {
    padding-right: 0.375rem !important;
  }
  .pb-xxl-1,
  .py-xxl-1 {
    padding-bottom: 0.375rem !important;
  }
  .pl-xxl-1,
  .px-xxl-1 {
    padding-left: 0.375rem !important;
  }
  .p-xxl-2 {
    padding: 0.75rem !important;
  }
  .pt-xxl-2,
  .py-xxl-2 {
    padding-top: 0.75rem !important;
  }
  .pr-xxl-2,
  .px-xxl-2 {
    padding-right: 0.75rem !important;
  }
  .pb-xxl-2,
  .py-xxl-2 {
    padding-bottom: 0.75rem !important;
  }
  .pl-xxl-2,
  .px-xxl-2 {
    padding-left: 0.75rem !important;
  }
  .p-xxl-3 {
    padding: 1rem !important;
  }
  .pt-xxl-3,
  .py-xxl-3 {
    padding-top: 1rem !important;
  }
  .pr-xxl-3,
  .px-xxl-3 {
    padding-right: 1rem !important;
  }
  .pb-xxl-3,
  .py-xxl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xxl-3,
  .px-xxl-3 {
    padding-left: 1rem !important;
  }
  .p-xxl-4 {
    padding: 1.5rem !important;
  }
  .pt-xxl-4,
  .py-xxl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xxl-4,
  .px-xxl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xxl-4,
  .py-xxl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xxl-4,
  .px-xxl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xxl-5 {
    padding: 2.75rem !important;
  }
  .pt-xxl-5,
  .py-xxl-5 {
    padding-top: 2.75rem !important;
  }
  .pr-xxl-5,
  .px-xxl-5 {
    padding-right: 2.75rem !important;
  }
  .pb-xxl-5,
  .py-xxl-5 {
    padding-bottom: 2.75rem !important;
  }
  .pl-xxl-5,
  .px-xxl-5 {
    padding-left: 2.75rem !important;
  }
  .p-xxl-gs {
    padding: 28px !important;
  }
  .pt-xxl-gs,
  .py-xxl-gs {
    padding-top: 28px !important;
  }
  .pr-xxl-gs,
  .px-xxl-gs {
    padding-right: 28px !important;
  }
  .pb-xxl-gs,
  .py-xxl-gs {
    padding-bottom: 28px !important;
  }
  .pl-xxl-gs,
  .px-xxl-gs {
    padding-left: 28px !important;
  }
  .m-xxl-n1 {
    margin: -0.375rem !important;
  }
  .mt-xxl-n1,
  .my-xxl-n1 {
    margin-top: -0.375rem !important;
  }
  .mr-xxl-n1,
  .mx-xxl-n1 {
    margin-right: -0.375rem !important;
  }
  .mb-xxl-n1,
  .my-xxl-n1 {
    margin-bottom: -0.375rem !important;
  }
  .ml-xxl-n1,
  .mx-xxl-n1 {
    margin-left: -0.375rem !important;
  }
  .m-xxl-n2 {
    margin: -0.75rem !important;
  }
  .mt-xxl-n2,
  .my-xxl-n2 {
    margin-top: -0.75rem !important;
  }
  .mr-xxl-n2,
  .mx-xxl-n2 {
    margin-right: -0.75rem !important;
  }
  .mb-xxl-n2,
  .my-xxl-n2 {
    margin-bottom: -0.75rem !important;
  }
  .ml-xxl-n2,
  .mx-xxl-n2 {
    margin-left: -0.75rem !important;
  }
  .m-xxl-n3 {
    margin: -1rem !important;
  }
  .mt-xxl-n3,
  .my-xxl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xxl-n3,
  .mx-xxl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xxl-n3,
  .my-xxl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xxl-n3,
  .mx-xxl-n3 {
    margin-left: -1rem !important;
  }
  .m-xxl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xxl-n4,
  .my-xxl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xxl-n4,
  .mx-xxl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xxl-n4,
  .my-xxl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xxl-n4,
  .mx-xxl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xxl-n5 {
    margin: -2.75rem !important;
  }
  .mt-xxl-n5,
  .my-xxl-n5 {
    margin-top: -2.75rem !important;
  }
  .mr-xxl-n5,
  .mx-xxl-n5 {
    margin-right: -2.75rem !important;
  }
  .mb-xxl-n5,
  .my-xxl-n5 {
    margin-bottom: -2.75rem !important;
  }
  .ml-xxl-n5,
  .mx-xxl-n5 {
    margin-left: -2.75rem !important;
  }
  .m-xxl-ngs {
    margin: -28px !important;
  }
  .mt-xxl-ngs,
  .my-xxl-ngs {
    margin-top: -28px !important;
  }
  .mr-xxl-ngs,
  .mx-xxl-ngs {
    margin-right: -28px !important;
  }
  .mb-xxl-ngs,
  .my-xxl-ngs {
    margin-bottom: -28px !important;
  }
  .ml-xxl-ngs,
  .mx-xxl-ngs {
    margin-left: -28px !important;
  }
  .m-xxl-auto {
    margin: auto !important;
  }
  .mt-xxl-auto,
  .my-xxl-auto {
    margin-top: auto !important;
  }
  .mr-xxl-auto,
  .mx-xxl-auto {
    margin-right: auto !important;
  }
  .mb-xxl-auto,
  .my-xxl-auto {
    margin-bottom: auto !important;
  }
  .ml-xxl-auto,
  .mx-xxl-auto {
    margin-left: auto !important;
  }
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: '';
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace,
.ff-mono {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono',
    'Courier New', monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

@media (min-width: 1540px) {
  .text-xxl-left {
    text-align: left !important;
  }
  .text-xxl-right {
    text-align: right !important;
  }
  .text-xxl-center {
    text-align: center !important;
  }
}

.text-lowercase,
.lcap {
  text-transform: lowercase !important;
}

.text-uppercase,
.ucap {
  text-transform: uppercase !important;
}

.text-capitalize,
.ccap {
  text-transform: capitalize !important;
}

.font-weight-light,
.fw-light {
  font-weight: 300 !important;
}

.font-weight-lighter,
.fw-lighter {
  font-weight: lighter !important;
}

.font-weight-normal,
.fw-normal {
  font-weight: 400 !important;
}

.font-weight-bold,
.fw-bold {
  font-weight: 700 !important;
}

.font-weight-bolder,
.fw-bolder {
  font-weight: bolder !important;
}

.font-italic,
.ff-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #6576ff !important;
}

a.text-primary:hover,
a.text-primary:focus {
  color: #1932ff !important;
}

.text-secondary {
  color: #364a63 !important;
}

a.text-secondary:hover,
a.text-secondary:focus {
  color: #1b2532 !important;
}

.text-success {
  color: #1ee0ac !important;
}

a.text-success:hover,
a.text-success:focus {
  color: #159d78 !important;
}

.text-info {
  color: #09c2de !important;
}

a.text-info:hover,
a.text-info:focus {
  color: #068294 !important;
}

.text-warning {
  color: #f4bd0e !important;
}

a.text-warning:hover,
a.text-warning:focus {
  color: #ae8608 !important;
}

.text-danger {
  color: #e85347 !important;
}

a.text-danger:hover,
a.text-danger:focus {
  color: #c92619 !important;
}

.text-dark {
  color: #1c2b46 !important;
}

a.text-dark:hover,
a.text-dark:focus {
  color: #06090f !important;
}

.text-gray {
  color: #8091a7 !important;
}

a.text-gray:hover,
a.text-gray:focus {
  color: #596b81 !important;
}

.text-light {
  color: #e5e9f2 !important;
}

a.text-light:hover,
a.text-light:focus {
  color: #b2bed9 !important;
}

.text-lighter {
  color: #f5f6fa !important;
}

a.text-lighter:hover,
a.text-lighter:focus {
  color: #c2c8e1 !important;
}

.text-body {
  color: #526484 !important;
}

.text-muted {
  color: #8094ae !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a sans-serif;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: ' (' attr(title) ')';
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #8091a7;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dbdfea !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #dbdfea;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #dbdfea;
  }
}

html {
  font-size: 16px;
}

ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  transition: color 0.4s, background-color 0.4s, border 0.4s, box-shadow 0.4s;
}

a:focus {
  outline: none;
}

img {
  max-width: 100%;
}

strong {
  font-weight: 500;
}

h1:last-child,
h2:last-child,
h3:last-child,
h4:last-child,
h5:last-child,
h6:last-child,
.h1:last-child,
.h2:last-child,
.h3:last-child,
.h4:last-child,
.h5:last-child,
.h6:last-child,
p:last-child {
  margin-bottom: 0;
}

h1,
.h1,
h2,
.h2 {
  letter-spacing: -0.03em;
}

h3,
.h3,
h4,
.h4 {
  letter-spacing: -0.02em;
}

h5,
.h5,
h6,
.h6 {
  letter-spacing: -0.01em;
}

@media (min-width: 992px) {
  h1,
  .h1 {
    font-size: 3.75rem;
    font-weight: 400;
    letter-spacing: -0.04em;
  }
  h2,
  .h2 {
    font-size: 2.5rem;
    letter-spacing: -0.03em;
  }
  h3,
  .h3 {
    font-size: 2rem;
    letter-spacing: -0.03em;
  }
  h4,
  .h4 {
    font-size: 1.5rem;
  }
  h5,
  .h5 {
    font-size: 1.25rem;
  }
  h6,
  .h6 {
    font-size: 1.05rem;
  }
}

.list-inline li {
  display: inline-block;
}

.list-inline li:not(:last-child) {
  margin-right: 1rem;
}

.ellipsis,
.text-ellipsis,
.tb-tnx-item .tb-tnx-desc,
.tb-odr-item .tb-odr-info,
.tb-ticket-item .title,
.attach-item span,
.nk-msg-text .title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ff-base {
  font-family: Roboto, sans-serif !important;
}

.ff-alt {
  font-family: var(--default-font), sans-serif !important;
}

.fw-medium {
  font-weight: 500 !important;
}

.text-white h1,
.text-white h2,
.text-white h3,
.text-white h4,
.text-white h5,
.text-white h6,
.text-white .h1,
.text-white .h2,
.text-white .h3,
.text-white .h4,
.text-white .h5,
.text-white .h6 {
  color: #fff;
}

@media (min-width: 992px) {
  .lead {
    font-size: 1.09375rem;
  }
}

.text-soft {
  color: #8094ae !important;
}

.text-base {
  color: #526484 !important;
}

.text-head {
  color: #364a63 !important;
}

.circle {
  border-radius: 50%;
}

.round {
  border-radius: 4px;
}

.round-sm {
  border-radius: 3px;
}

.round-lg {
  border-radius: 5px;
}

.round-xl {
  border-radius: 10px;
}

svg {
  height: 100%;
  max-width: 100%;
}

.sq {
  height: 40px;
  width: 40px;
}

.sq-xs {
  height: 18px;
  width: 18px;
}

.sq-sm {
  height: 24px;
  width: 24px;
}

.sq-md {
  height: 32px;
  width: 32px;
}

.sq-lg {
  height: 60px;
  width: 60px;
}

.sq-xl {
  height: 80px;
  width: 80px;
}

.divider {
  border-top: 1px solid #e5e9f2 !important;
  margin: 28px 0;
  display: block;
}

.divider.xs {
  margin: 0.5rem 0;
}

.divider.sm {
  margin: 1rem 0;
}

.divider.md {
  margin: 1.5rem 0;
}

.divider.lg {
  margin: 2.25rem 0;
}

.divider.xl {
  margin: 2.75rem 0;
}

.bg-blue {
  background-color: #559bfb !important;
}

.text-blue {
  color: #559bfb !important;
}

.bg-azure {
  background-color: #1676fb !important;
}

.text-azure {
  color: #1676fb !important;
}

.bg-indigo {
  background-color: #2c3782 !important;
}

.text-indigo {
  color: #2c3782 !important;
}

.bg-purple {
  background-color: #816bff !important;
}

.text-purple {
  color: #816bff !important;
}

.bg-pink {
  background-color: #ff63a5 !important;
}

.text-pink {
  color: #ff63a5 !important;
}

.bg-orange {
  background-color: #ffa353 !important;
}

.text-orange {
  color: #ffa353 !important;
}

.bg-teal {
  background-color: #20c997 !important;
}

.text-teal {
  color: #20c997 !important;
}

.bg-blue-dim {
  background-color: #e9f2fe !important;
}

.text-blue-dim {
  color: #e9f2fe !important;
}

.bg-azure-dim {
  background-color: #e1edfe !important;
}

.text-azure-dim {
  color: #e1edfe !important;
}

.bg-indigo-dim {
  background-color: #e4e5ef !important;
}

.text-indigo-dim {
  color: #e4e5ef !important;
}

.bg-purple-dim {
  background-color: #efecff !important;
}

.text-purple-dim {
  color: #efecff !important;
}

.bg-pink-dim {
  background-color: #ffebf3 !important;
}

.text-pink-dim {
  color: #ffebf3 !important;
}

.bg-orange-dim {
  background-color: #fff3e9 !important;
}

.text-orange-dim {
  color: #fff3e9 !important;
}

.bg-teal-dim {
  background-color: #e2f8f1 !important;
}

.text-teal-dim {
  color: #e2f8f1 !important;
}

.bg-primary-dim {
  background-color: #ebedff !important;
}

.text-primary-dim {
  color: #ebedff !important;
}

.bg-success-dim {
  background-color: #e2fbf4 !important;
}

.text-success-dim {
  color: #e2fbf4 !important;
}

.bg-info-dim {
  background-color: #dff7fb !important;
}

.text-info-dim {
  color: #dff7fb !important;
}

.bg-warning-dim {
  background-color: #fef6e0 !important;
}

.text-warning-dim {
  color: #fef6e0 !important;
}

.bg-danger-dim {
  background-color: #fce9e7 !important;
}

.text-danger-dim {
  color: #fce9e7 !important;
}

.bg-secondary-dim {
  background-color: #e5ecf5 !important;
}

.text-secondary-dim {
  color: #e5ecf5 !important;
}

.bg-dark-dim {
  background-color: #d9e1ef !important;
}

.text-dark-dim {
  color: #d9e1ef !important;
}

.bg-gray-dim {
  background-color: #edf2f9 !important;
}

.text-gray-dim {
  color: #edf2f9 !important;
}

.bg-gray-100 {
  background-color: #ebeef2 !important;
}

.bg-gray-200 {
  background-color: #e5e9f2 !important;
}

.bg-gray-300 {
  background-color: #dbdfea !important;
}

.bg-gray-400 {
  background-color: #b7c2d0 !important;
}

.bg-gray-500 {
  background-color: #8091a7 !important;
}

.bg-gray-600 {
  background-color: #3c4d62 !important;
}

.bg-gray-700 {
  background-color: #344357 !important;
}

.bg-gray-800 {
  background-color: #2b3748 !important;
}

.bg-gray-900 {
  background-color: #1f2b3a !important;
}

.bg-abstract {
  background-image: linear-gradient(
    to right,
    #2c3782 calc(60% - 150px),
    #39469f calc(60% - 150px),
    #39469f 60%,
    #4856b5 60%,
    #4856b5 calc(60% + 150px),
    #5b6ac6 calc(60% + 150px),
    #5b6ac6 100%
  );
}

.bg-white-1 {
  background: rgba(255, 255, 255, 0.1) !important;
}

.bg-white-2 {
  background: rgba(255, 255, 255, 0.2) !important;
}

.bg-white-3 {
  background: rgba(255, 255, 255, 0.3) !important;
}

.bg-white-4 {
  background: rgba(255, 255, 255, 0.4) !important;
}

.bg-white-5 {
  background: rgba(255, 255, 255, 0.5) !important;
}

.bg-white-6 {
  background: rgba(255, 255, 255, 0.6) !important;
}

.bg-white-7 {
  background: rgba(255, 255, 255, 0.7) !important;
}

.bg-white-8 {
  background: rgba(255, 255, 255, 0.8) !important;
}

.bg-white-9 {
  background: rgba(255, 255, 255, 0.9) !important;
}

.border-transparent {
  border-color: transparent;
}

.w-20px {
  width: 20px !important;
}

.w-50px {
  width: 50px !important;
}

.w-80px {
  width: 80px !important;
}

.w-min-80px {
  min-width: 80px !important;
}

.w-max-80px {
  max-width: 80px !important;
}

.w-90px {
  width: 90px !important;
}

.w-min-90px {
  min-width: 90px !important;
}

.w-max-90px {
  max-width: 90px !important;
}

.w-100px {
  width: 100px !important;
}

.w-min-100px {
  min-width: 100px !important;
}

.w-max-100px {
  max-width: 100px !important;
}

.w-110px {
  width: 110px !important;
}

.w-min-110px {
  min-width: 110px !important;
}

.w-max-110px {
  max-width: 110px !important;
}

.w-120px {
  width: 120px !important;
}

.w-min-120px {
  min-width: 120px !important;
}

.w-max-120px {
  max-width: 120px !important;
}

.w-125px {
  width: 125px !important;
}

.w-min-125px {
  min-width: 125px !important;
}

.w-max-125px {
  max-width: 125px !important;
}

.w-130px {
  width: 130px !important;
}

.w-min-130px {
  min-width: 130px !important;
}

.w-max-130px {
  max-width: 130px !important;
}

.w-140px {
  width: 140px !important;
}

.w-min-140px {
  min-width: 140px !important;
}

.w-max-140px {
  max-width: 140px !important;
}

.w-150px {
  width: 150px !important;
}

.w-min-150px {
  min-width: 150px !important;
}

.w-max-150px {
  max-width: 150px !important;
}

.w-175px {
  width: 175px !important;
}

.w-min-175px {
  min-width: 175px !important;
}

.w-max-175px {
  max-width: 175px !important;
}

.w-200px {
  width: 200px !important;
}

.w-min-200px {
  min-width: 200px !important;
}

.w-max-200px {
  max-width: 200px !important;
}

.w-220px {
  width: 220px !important;
}

.w-min-220px {
  min-width: 220px !important;
}

.w-max-220px {
  max-width: 220px !important;
}

.w-225px {
  width: 225px !important;
}

.w-min-225px {
  min-width: 225px !important;
}

.w-max-225px {
  max-width: 225px !important;
}

.w-250px {
  width: 250px !important;
}

.w-min-250px {
  min-width: 250px !important;
}

.w-max-250px {
  max-width: 250px !important;
}

.w-300px {
  width: 300px !important;
}

.w-min-300px {
  min-width: 300px !important;
}

.w-max-300px {
  max-width: 300px !important;
}

.w-350px {
  width: 350px !important;
}

.w-min-350px {
  min-width: 350px !important;
}

.w-max-350px {
  max-width: 350px !important;
}

.w-400px {
  width: 400px !important;
}

.w-min-400px {
  min-width: 400px !important;
}

.w-max-400px {
  max-width: 400px !important;
}

.w-550px {
  width: 550px !important;
}

.w-min-550px {
  min-width: 550px !important;
}

.w-max-550px {
  max-width: 550px !important;
}

.w-max-100,
.wide-xs-fix,
.wide-sm-fix,
.wide-md-fix,
.wide-lg-fix {
  max-width: 100% !important;
}

.w-min-100 {
  min-width: 100% !important;
}

.w-max-auto {
  max-width: inherit !important;
}

.w-min-auto {
  min-width: auto !important;
}

.w-15 {
  width: 15% !important;
}

.w-20 {
  width: 20% !important;
}

.w-30 {
  width: 30% !important;
}

.w-35 {
  width: 35% !important;
}

.w-40 {
  width: 40% !important;
}

.w-45 {
  width: 45% !important;
}

.w-55 {
  width: 55% !important;
}

.w-60 {
  width: 60% !important;
}

.w-65 {
  width: 65% !important;
}

.w-70 {
  width: 70% !important;
}

.w-80 {
  width: 80% !important;
}

.w-85 {
  width: 85% !important;
}

.w-90 {
  width: 90% !important;
}

.w-95 {
  width: 95% !important;
}

@media (min-width: 576px) {
  .wide-xs {
    max-width: 520px !important;
  }
  .wide-xs-fix {
    width: 520px !important;
  }
}

@media (min-width: 768px) {
  .wide-sm {
    max-width: 720px !important;
  }
  .wide-sm-fix {
    width: 720px !important;
  }
}

@media (min-width: 992px) {
  .wide-md {
    max-width: 960px !important;
  }
  .wide-md-fix {
    width: 960px !important;
  }
}

@media (min-width: 1200px) {
  .wide-lg {
    max-width: 1140px !important;
  }
  .wide-lg-fix {
    width: 1140px !important;
  }
}

@media (min-width: 1280px) {
  .wide-xl {
    max-width: 1240px !important;
  }
  .wide-xl-fix {
    width: 1240px !important;
  }
}

.wide-xs-fix {
  width: 520px !important;
}

.wide-sm-fix {
  width: 720px !important;
}

.wide-md-fix {
  width: 960px !important;
}

.wide-lg-fix {
  width: 1140px !important;
}

.wide-xl-fix {
  width: 1240px !important;
}

@media (min-width: 1200px) and (max-width: 1660px) {
  .wide-fit {
    max-width: 1140px;
  }
}

.h-100px {
  height: 100px !important;
}

.h-min-100px {
  min-height: 100px !important;
}

.h-max-100px {
  max-height: 100px !important;
}

.h-150px {
  height: 150px !important;
}

.h-min-150px {
  min-height: 150px !important;
}

.h-max-150px {
  max-height: 150px !important;
}

.h-175px {
  height: 175px !important;
}

.h-min-175px {
  min-height: 175px !important;
}

.h-max-175px {
  max-height: 175px !important;
}

.h-200px {
  height: 200px !important;
}

.h-min-200px {
  min-height: 200px !important;
}

.h-max-200px {
  max-height: 200px !important;
}

.h-225px {
  height: 225px !important;
}

.h-min-225px {
  min-height: 225px !important;
}

.h-max-225px {
  max-height: 225px !important;
}

.h-250px {
  height: 250px !important;
}

.h-min-250px {
  min-height: 250px !important;
}

.h-max-250px {
  max-height: 250px !important;
}

.h-275px {
  height: 275px !important;
}

.h-min-275px {
  min-height: 275px !important;
}

.h-max-275px {
  max-height: 275px !important;
}

.h-300px {
  height: 300px !important;
}

.h-min-300px {
  min-height: 300px !important;
}

.h-max-300px {
  max-height: 300px !important;
}

.h-325px {
  height: 325px !important;
}

.h-min-325px {
  min-height: 325px !important;
}

.h-max-325px {
  max-height: 325px !important;
}

.h-350px {
  height: 350px !important;
}

.h-min-350px {
  min-height: 350px !important;
}

.h-max-350px {
  max-height: 350px !important;
}

.h-375px {
  height: 375px !important;
}

.h-min-375px {
  min-height: 375px !important;
}

.h-max-375px {
  max-height: 375px !important;
}

.h-400px {
  height: 400px !important;
}

.h-min-400px {
  min-height: 400px !important;
}

.h-max-400px {
  max-height: 400px !important;
}

.h-425px {
  height: 425px !important;
}

.h-min-425px {
  min-height: 425px !important;
}

.h-max-425px {
  max-height: 425px !important;
}

.h-450px {
  height: 450px !important;
}

.h-min-450px {
  min-height: 450px !important;
}

.h-max-450px {
  max-height: 450px !important;
}

.h-475px {
  height: 475px !important;
}

.h-min-475px {
  min-height: 475px !important;
}

.h-max-475px {
  max-height: 475px !important;
}

.h-500px {
  height: 500px !important;
}

.h-min-500px {
  min-height: 500px !important;
}

.h-max-500px {
  max-height: 500px !important;
}

.absolute-top-left {
  position: absolute;
  top: 0;
  left: 0;
}

.absolute-top-right {
  position: absolute;
  top: 0;
  right: 0;
}

.absolute-bottom-left {
  position: absolute;
  bottom: 0;
  left: 0;
}

.absolute-bottom-right {
  position: absolute;
  bottom: 0;
  right: 0;
}

.shrink-0 {
  flex-shrink: 0;
}

.badge {
  position: relative;
  border: 1px solid currentColor;
  line-height: 1.125rem;
  font-family: Roboto, sans-serif;
  letter-spacing: 0.01em;
  vertical-align: middle;
  display: inline-flex;
}

.badge .icon + span,
.badge span + .icon {
  padding-left: 4px;
}

.badge-sm {
  padding: 0.3125rem 0.75rem;
  font-size: 0.75rem;
}

.badge-sm.badge-pill {
  padding: 0.3125rem 0.875rem;
}

.badge-md {
  padding: 0.47rem 1rem;
  font-size: 0.8125rem !important;
}

.badge-lg {
  padding: 0.75rem 1.25rem;
  font-size: 0.93rem !important;
}

.badge-xl {
  padding: 0.875rem 1.5rem;
  font-size: 1.125rem !important;
}

.badge-light {
  color: #364a63;
}

.badge-lighter {
  color: #526484;
}

.badge .icon {
  font-size: 1rem;
}

.badge-primary {
  border-color: #6576ff;
}

.badge-secondary {
  border-color: #364a63;
}

.badge-success {
  border-color: #1ee0ac;
}

.badge-info {
  border-color: #09c2de;
}

.badge-warning {
  border-color: #f4bd0e;
}

.badge-danger {
  border-color: #e85347;
}

.badge-dark {
  border-color: #1c2b46;
}

.badge-gray {
  border-color: #8091a7;
}

.badge-light {
  border-color: #e5e9f2;
}

.badge-lighter {
  border-color: #f5f6fa;
}

.badge-outline-primary {
  color: #6576ff;
  border-color: #a3adff;
}

.badge-outline-secondary {
  color: #364a63;
  border-color: #8692a1;
}

.badge-outline-success {
  color: #1ee0ac;
  border-color: #78eccd;
}

.badge-outline-info {
  color: #09c2de;
  border-color: #6bdaeb;
}

.badge-outline-warning {
  color: #f4bd0e;
  border-color: #f8d76e;
}

.badge-outline-danger {
  color: #e85347;
  border-color: #f19891;
}

.badge-outline-teal {
  color: #20c997;
  border-color: #20c997;
}

.badge-outline-dark {
  color: #1c2b46;
  border-color: #778090;
}

.badge-outline-gray {
  color: #8091a7;
  border-color: #b3bdca;
}

.badge-outline-light {
  color: #b7c2d0;
  border-color: #d4dae3;
}

.badge-outline-lighter {
  color: #e5e9f2;
  border-color: #eff2f7;
}

.badge-dim.badge-primary {
  color: #6576ff;
  background-color: #eef0ff;
  border-color: #eef0ff;
}

.badge-dim.badge-outline-primary {
  color: #6576ff;
  background-color: #eef0ff;
  border-color: #c1c8ff;
}

.badge-dim.badge-secondary {
  color: #364a63;
  background-color: #e9ebee;
  border-color: #e9ebee;
}

.badge-dim.badge-outline-secondary {
  color: #364a63;
  background-color: #e9ebee;
  border-color: #afb7c1;
}

.badge-dim.badge-success {
  color: #1ee0ac;
  background-color: #e6fcf6;
  border-color: #e6fcf6;
}

.badge-dim.badge-outline-success {
  color: #1ee0ac;
  background-color: #e6fcf6;
  border-color: #a5f3de;
}

.badge-dim.badge-info {
  color: #09c2de;
  background-color: #e4f8fb;
  border-color: #e4f8fb;
}

.badge-dim.badge-outline-info {
  color: #09c2de;
  background-color: #e4f8fb;
  border-color: #9de7f2;
}

.badge-dim.badge-warning {
  color: #f4bd0e;
  background-color: #fef8e4;
  border-color: #fef8e4;
}

.badge-dim.badge-outline-warning {
  color: #f4bd0e;
  background-color: #fef8e4;
  border-color: #fbe59f;
}

.badge-dim.badge-danger {
  color: #e85347;
  background-color: #fceceb;
  border-color: #fceceb;
}

.badge-dim.badge-outline-danger {
  color: #e85347;
  background-color: #fceceb;
  border-color: #f6bab5;
}

.badge-dim.badge-outline-teal {
  color: #20c997;
  background-color: #fceceb;
  border-color: #20c997;
}

.badge-dim.badge-dark {
  color: #1c2b46;
  background-color: #e6e8eb;
  border-color: #e6e8eb;
}

.badge-dim.badge-outline-dark {
  color: #1c2b46;
  background-color: #e6e8eb;
  border-color: #a4aab5;
}

.badge-dim.badge-gray {
  color: #8091a7;
  background-color: #f1f3f5;
  border-color: #f1f3f5;
}

.badge-dim.badge-outline-gray {
  color: #8091a7;
  background-color: #f1f3f5;
  border-color: #ccd3dc;
}

.badge-dim.badge-light {
  color: #b7c2d0;
  background-color: #f7f8fa;
  border-color: #f7f8fa;
}

.badge-dim.badge-outline-light {
  color: #b7c2d0;
  background-color: #f7f8fa;
  border-color: #e2e7ec;
}

.badge-dim.badge-lighter {
  color: #e5e9f2;
  background-color: #fcfdfe;
  border-color: #fcfdfe;
}

.badge-dim.badge-outline-lighter {
  color: #e5e9f2;
  background-color: #fcfdfe;
  border-color: #f5f6fa;
}

.badge-dot {
  display: inline-flex;
  align-items: center;
  background-color: transparent;
  border: none;
  padding-left: 12px;
  padding-right: 0;
  font-size: 12px;
}

.badge-dot:before {
  position: absolute;
  content: '';
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  display: inline-block;
  height: 6px;
  width: 6px;
  margin-top: -1px;
  border-radius: 50%;
  background: currentColor;
}

.badge-dot.badge-sm,
.badge-dot.badge-md {
  padding-left: 16px;
}

.badge-dot.badge-sm:before,
.badge-dot.badge-md:before {
  height: 8px;
  width: 8px;
  margin-top: 0;
}

.badge-dot.badge-lg,
.badge-dot.badge-xl {
  padding-left: 24px;
}

.badge-dot.badge-lg:before,
.badge-dot.badge-xl:before {
  height: 12px;
  width: 12px;
  margin-top: 0;
}

.badge-dot.badge-primary {
  color: #6576ff;
}

.badge-dot.badge-secondary {
  color: #364a63;
}

.badge-dot.badge-success {
  color: #1ee0ac;
}

.badge-dot.badge-info {
  color: #09c2de;
}

.badge-dot.badge-warning {
  color: #f4bd0e;
}

.badge-dot.badge-danger {
  color: #e85347;
}

.badge-dot.badge-dark {
  color: #1c2b46;
}

.badge-dot.badge-gray {
  color: #8091a7;
}

.badge-dot.badge-light {
  color: #b7c2d0;
}

.badge-dot.badge-lighter {
  color: #e5e9f2;
}

@media (max-width: 767.98px) {
  .badge-dot-sm {
    width: 0;
    overflow: hidden;
  }
}

@media (max-width: 413px) {
  .badge-dot-mb {
    width: 0;
    overflow: hidden;
  }
}

@media (max-width: 369px) {
  .badge-dot-xs {
    width: 0;
    overflow: hidden;
  }
}

.alert:last-child {
  margin-bottom: 0;
}

.alert-link {
  box-shadow: 0 1px 0 currentColor;
}

.alert-link:hover {
  box-shadow: none;
}

.alert h5,
.alert h6,
.alert .title {
  font-size: 1.05rem;
}

.alert.alert-icon {
  padding-left: 3.25rem;
}

.alert.alert-icon > .icon {
  position: absolute;
  font-size: 1.25rem;
  line-height: 1;
  width: 2rem;
  top: 1rem;
  left: 1.25rem;
  margin-left: -1px;
}

.alert-dismissible .close {
  top: 50%;
  transform: translateY(-50%);
  transition: 0.3s ease;
  margin-top: -1px;
}

.alert-dismissible .close:focus {
  outline: none;
}

.alert-dismissible .close:hover {
  color: currentColor;
}

.alert-dismissible .close:after {
  font-family: 'Nioicon', sans-serif;
  content: '';
  font-size: 1.1rem;
}

.alert-light,
.alert-lighter,
.alert-gray {
  border-color: #e5e9f2;
  color: #526484;
}

.alert-light .alert-link,
.alert-lighter .alert-link,
.alert-gray .alert-link {
  color: #526484;
}

.alert-alt {
  position: relative;
  padding: 0.875rem;
  border-radius: 12px;
}

.alert-alt.alert-icon {
  padding-left: 4rem;
}

.alert-alt.alert-icon > .icon {
  position: absolute;
  font-size: 2rem;
  line-height: 1;
  width: 4rem;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  text-align: center;
  opacity: 0.5;
}

a.alert:hover {
  color: currentColor;
}

.alert-fill.alert-primary {
  color: #fff;
  background: #6576ff;
}

.alert-fill.alert-secondary {
  color: #fff;
  background: #364a63;
}

.alert-fill.alert-success {
  color: #fff;
  background: #1ee0ac;
}

.alert-fill.alert-info {
  color: #fff;
  background: #09c2de;
}

.alert-fill.alert-warning {
  color: #fff;
  background: #f4bd0e;
}

.alert-fill.alert-danger {
  color: #fff;
  background: #e85347;
}

.alert-fill.alert-dark {
  color: #fff;
  background: #1c2b46;
}

.alert-fill.alert-gray {
  color: #fff;
  background: #8091a7;
}

.alert-fill.alert-light {
  color: #101924;
  background: #e5e9f2;
}

.alert-fill.alert-lighter {
  color: #101924;
  background: #ebeef2;
}

.alert-fill {
  border-width: 0;
}

.alert-fill .alert-link,
.alert-fill .icon,
.alert-fill h4,
.alert-fill h5,
.alert-fill h6 {
  color: currentColor;
}

.alert-fill.alert-light {
  color: #364a63;
}

.alert-fill.alert-lighter {
  color: #526484;
}

.alert-pro {
  box-shadow: 0 4px 15px 0 rgba(31, 43, 58, 0.1);
  border: none;
  border-left: 4px solid transparent;
  color: #526484;
  line-height: 1.5;
  background: #fff;
}

.alert-pro.no-shadow {
  box-shadow: none;
}

.alert-pro.no-border {
  border-left: 0;
}

.alert-pro.alert-icon {
  padding-left: 4rem;
}

.alert-pro.alert-icon > .icon {
  font-size: 1.75rem;
  top: 50%;
  transform: translateY(-50%);
}

.alert-pro.alert-primary {
  border-color: #6576ff;
}

.alert-pro.alert-primary > .icon {
  color: #6576ff;
}

.alert-pro.alert-secondary {
  border-color: #364a63;
}

.alert-pro.alert-secondary > .icon {
  color: #364a63;
}

.alert-pro.alert-success {
  border-color: #1ee0ac;
}

.alert-pro.alert-success > .icon {
  color: #1ee0ac;
}

.alert-pro.alert-info {
  border-color: #09c2de;
}

.alert-pro.alert-info > .icon {
  color: #09c2de;
}

.alert-pro.alert-warning {
  border-color: #f4bd0e;
}

.alert-pro.alert-warning > .icon {
  color: #f4bd0e;
}

.alert-pro.alert-danger {
  border-color: #e85347;
}

.alert-pro.alert-danger > .icon {
  color: #e85347;
}

.alert-pro.alert-dark {
  border-color: #1c2b46;
}

.alert-pro.alert-dark > .icon {
  color: #1c2b46;
}

.alert-pro.alert-gray {
  border-color: #8091a7;
}

.alert-pro.alert-gray > .icon {
  color: #8091a7;
}

.alert-pro.alert-light {
  border-color: #b7c2d0;
}

.alert-pro.alert-light > .icon {
  color: #b7c2d0;
}

.alert-pro.alert-lighter {
  border-color: #b7c2d0;
}

.alert-pro.alert-lighter > .icon {
  color: #b7c2d0;
}

.breadcrumb-item {
  font-size: 11px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
}

.breadcrumb-item a:not(:hover) {
  color: #8094ae;
}

.breadcrumb-pipe .breadcrumb-item + .breadcrumb-item::before {
  content: '|';
}

.breadcrumb-arrow .breadcrumb-item + .breadcrumb-item::before {
  font-family: 'Nioicon', sans-serif;
  content: '';
}

.breadcrumb-item.active:not(:only-child) {
  font-weight: 400;
}

.breadcrumb-alt .breadcrumb-item {
  text-transform: unset;
  letter-spacing: 0;
  color: #8094ae;
}

.breadcrumb-xs .breadcrumb-item + .breadcrumb-item {
  padding-left: 0.25rem;
}

.breadcrumb-xs .breadcrumb-item + .breadcrumb-item:before {
  padding-right: 0.25rem;
  font-size: 0.8em;
}

.breadcrumb-sm .breadcrumb-item {
  font-size: 13px;
}

.link {
  display: inline-flex;
  align-items: center;
  font-size: 0.85rem;
  font-weight: 500;
  font-family: Roboto, sans-serif;
  line-height: 1;
  border: none;
  background: transparent;
  transition: all 0.3s ease;
  white-space: nowrap;
}

.link:focus {
  box-shadow: none;
  outline: none;
}

.link .icon {
  font-size: 1.3em;
}

.link span,
.link .icon {
  display: inline-block;
}

.link .icon-circle {
  display: inline-flex;
}

.link .icon + span,
.link span + .icon {
  padding-left: 10px;
}

.link-md {
  font-size: 1.25rem;
}

.link-sm {
  font-size: 0.75rem;
}

.link-dim {
  opacity: 0.7;
}

.link-dim:hover {
  opacity: 1;
}

.link-block {
  display: flex;
}

.link-between {
  justify-content: space-between;
}

.link-primary {
  color: #6576ff !important;
}

.link-on-primary:hover {
  color: #6576ff !important;
}

.link-secondary {
  color: #364a63 !important;
}

.link-on-secondary:hover {
  color: #364a63 !important;
}

.link-success {
  color: #1ee0ac !important;
}

.link-on-success:hover {
  color: #1ee0ac !important;
}

.link-info {
  color: #09c2de !important;
}

.link-on-info:hover {
  color: #09c2de !important;
}

.link-warning {
  color: #f4bd0e !important;
}

.link-on-warning:hover {
  color: #f4bd0e !important;
}

.link-danger {
  color: #e85347 !important;
}

.link-on-danger:hover {
  color: #e85347 !important;
}

.link-dark {
  color: #1c2b46 !important;
}

.link-on-dark:hover {
  color: #1c2b46 !important;
}

.link-gray {
  color: #8091a7 !important;
}

.link-on-gray:hover {
  color: #8091a7 !important;
}

.link-light {
  color: #8094ae !important;
}

.link-on-light:hover {
  color: #8094ae !important;
}

.link-lighter {
  color: #b7c2d0 !important;
}

.link-on-lighter:hover {
  color: #b7c2d0 !important;
}

.link-text {
  color: #526484 !important;
}

.link-on-text:hover {
  color: #526484 !important;
}

.link-danger:hover,
.link-warning:hover,
.link-success:hover,
.link-info:hover {
  color: #526484 !important;
}

.btn {
  position: relative;
  letter-spacing: 0.02em;
  display: inline-flex;
  align-items: center;
}

.btn-xl {
  padding: 0.6875rem 1.5rem;
  font-size: 0.9375rem;
  line-height: 1.25rem;
  border-radius: 5px;
}

.btn-xs {
  padding: 0.125rem 0.5rem;
  font-size: 0.6875rem;
  line-height: 1rem;
  border-radius: 3px;
}

.btn .icon {
  font-size: 1.4em;
  line-height: inherit;
}

.btn > span {
  display: inline-block;
  white-space: nowrap;
}

.btn > span:only-child {
  width: 100%;
}

.btn .icon + span,
.btn span + .icon {
  padding-left: 8px;
}

.btn .dd-indc {
  transform: translateX(-8px);
}

.btn span + .dd-indc {
  transform: translateX(8px);
}

.btn-lg .icon + span,
.btn-group-lg > .btn .icon + span,
.btn-lg span + .icon,
.btn-group-lg > .btn span + .icon {
  padding-left: 12px;
}

.btn-round {
  border-radius: 2.125rem;
}

.btn-block {
  justify-content: center;
}

.btn-ucap,
.btn.ucap {
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 0.05em;
}

.btn-icon:not([class*='btn-icon-break']) {
  padding-left: 0;
  padding-right: 0;
}

.btn-icon .icon {
  width: 2.125rem;
}

.btn-icon.btn-xl .icon {
  width: 2.625rem;
}

.btn-icon.btn-lg .icon,
.btn-group-lg > .btn-icon.btn .icon {
  width: 2.625rem;
}

.btn-icon.btn-sm .icon,
.btn-group-sm > .btn-icon.btn .icon {
  width: 1.75rem;
}

.btn-icon.btn-xs .icon {
  width: 1.25rem;
  font-size: 1.1em;
}

.btn-icon .dot {
  position: absolute;
  top: 0.35rem;
  right: 0.35rem;
  transform: translate(50%, -50%);
}

.btn-icon .badge {
  position: absolute;
  top: 0.25rem;
  right: 0.25rem;
  transform: translate(50%, -50%);
}

.btn-icon .badge-circle {
  border-radius: 50%;
  height: 1.125rem;
  width: 1.125rem;
  padding: 0;
  font-weight: 700;
  font-size: 11px;
  text-align: center;
  justify-content: center;
}

.btn-mw {
  min-width: 120px;
  justify-content: center;
}

.btn-wrap {
  flex-direction: column;
  align-items: center;
  flex-grow: 0;
}

.btn-extext {
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  margin-top: 0.25rem;
  color: #8094ae;
}

.btn-wider {
  display: flex;
}

.btn-wider .icon + span,
.btn-wider span + .icon {
  margin-left: auto;
}

.btn-auto {
  min-width: auto;
}

.btn-pd-auto {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.btn .spinner-border,
.btn .spinner-grow {
  margin: 0.125rem;
}

.btn .spinner-border + span,
.btn .spinner-grow + span {
  margin-left: 0.25rem;
}

.btn-indc {
  width: 100%;
}

.btn-indc .icon {
  font-size: 1.43em;
}

.btn-indc .indc {
  opacity: 0.6;
  margin-left: -8px;
  margin-right: auto;
}

.btn-indc span + .indc {
  margin-left: auto;
  margin-right: -8px;
}

@media (min-width: 768px) {
  .btn-xl {
    padding: 0.625rem 2rem;
    font-size: 1.125rem;
    line-height: 2rem;
    border-radius: 5px;
  }
  .btn-xl.btn-round {
    border-radius: 3.25rem;
  }
  .btn-icon.btn-xl .icon {
    width: 3.25rem;
  }
}

.btn-trigger {
  position: relative;
  z-index: 1;
  color: #526484;
}

.btn-trigger:focus {
  box-shadow: none;
}

.btn-trigger:before {
  position: absolute;
  z-index: -1;
  height: 20px;
  width: 20px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform-origin: 50% 50%;
  content: '';
  background-color: #e5e9f2;
  border-radius: 50%;
  opacity: 0;
  transition: all 0.3s;
}

.is-dark .btn-trigger:before {
  background-color: #1f275c;
}

.show > .btn-trigger:before {
  opacity: 1;
  height: 120%;
  width: 120%;
}

.btn-trigger:hover:before,
.btn-trigger:focus:before,
.btn-trigger.active:not(.revarse):before {
  opacity: 1;
  height: 120%;
  width: 120%;
}

.btn-trigger.active:hover:before {
  background-color: #dbdfea;
}

a:hover .btn-trigger:before {
  opacity: 1;
  height: 120%;
  width: 120%;
}

.btn-dim.btn-outline-primary {
  color: #6576ff;
  background-color: #eef0ff;
  border-color: #c1c8ff;
}

.btn-dim.btn-outline-primary:not(:disabled):not(.disabled):hover {
  color: #fff;
  background-color: #6576ff;
  border-color: #6576ff;
}

.btn-white.btn-outline-primary:not(.btn-dim):not(:disabled):not(
    .disabled
  ):hover,
.btn-trans.btn-outline-primary:not(.btn-dim):not(:disabled):not(
    .disabled
  ):hover {
  color: #6576ff;
  background: #eef0ff;
}

.btn-dim.btn-outline-success {
  color: #1ee0ac;
  background-color: #e6fcf6;
  border-color: #a5f3de;
}

.btn-dim.btn-outline-success:not(:disabled):not(.disabled):hover {
  color: #fff;
  background-color: #1ee0ac;
  border-color: #1ee0ac;
}

.btn-white.btn-outline-success:not(.btn-dim):not(:disabled):not(
    .disabled
  ):hover,
.btn-trans.btn-outline-success:not(.btn-dim):not(:disabled):not(
    .disabled
  ):hover {
  color: #1ee0ac;
  background: #e6fcf6;
}

.btn-dim.btn-outline-warning {
  color: #f4bd0e;
  background-color: #fef8e4;
  border-color: #fbe59f;
}

.btn-dim.btn-outline-warning:not(:disabled):not(.disabled):hover {
  color: #fff;
  background-color: #f4bd0e;
  border-color: #f4bd0e;
}

.btn-white.btn-outline-warning:not(.btn-dim):not(:disabled):not(
    .disabled
  ):hover,
.btn-trans.btn-outline-warning:not(.btn-dim):not(:disabled):not(
    .disabled
  ):hover {
  color: #f4bd0e;
  background: #fef8e4;
}

.btn-dim.btn-outline-info {
  color: #09c2de;
  background-color: #e4f8fb;
  border-color: #9de7f2;
}

.btn-dim.btn-outline-info:not(:disabled):not(.disabled):hover {
  color: #fff;
  background-color: #09c2de;
  border-color: #09c2de;
}

.btn-white.btn-outline-info:not(.btn-dim):not(:disabled):not(.disabled):hover,
.btn-trans.btn-outline-info:not(.btn-dim):not(:disabled):not(.disabled):hover {
  color: #09c2de;
  background: #e4f8fb;
}

.btn-dim.btn-outline-danger {
  color: #e85347;
  background-color: #fceceb;
  border-color: #f6bab5;
}

.btn-dim.btn-outline-danger:not(:disabled):not(.disabled):hover {
  color: #fff;
  background-color: #e85347;
  border-color: #e85347;
}

.btn-white.btn-outline-danger:not(.btn-dim):not(:disabled):not(.disabled):hover,
.btn-trans.btn-outline-danger:not(.btn-dim):not(:disabled):not(
    .disabled
  ):hover {
  color: #e85347;
  background: #fceceb;
}

.btn-dim.btn-outline-secondary {
  color: #364a63;
  background-color: #e9ebee;
  border-color: #b9c0c8;
}

.btn-dim.btn-outline-secondary:not(:disabled):not(.disabled):hover {
  color: #fff;
  background-color: #364a63;
  border-color: #364a63;
}

.btn-white.btn-outline-secondary:not(.btn-dim):not(:disabled):not(
    .disabled
  ):hover,
.btn-trans.btn-outline-secondary:not(.btn-dim):not(:disabled):not(
    .disabled
  ):hover {
  color: #364a63;
  background: #e9ebee;
}

.btn-dim.btn-outline-gray {
  color: #3c4d62;
  background-color: #f1f3f5;
  border-color: #d3d9e0;
}

.btn-dim.btn-outline-gray:not(:disabled):not(.disabled):hover {
  color: #fff;
  background-color: #3c4d62;
  border-color: #3c4d62;
}

.btn-white.btn-outline-gray:not(.btn-dim):not(:disabled):not(.disabled):hover,
.btn-trans.btn-outline-gray:not(.btn-dim):not(:disabled):not(.disabled):hover {
  color: #3c4d62;
  background: #f1f3f5;
}

.btn-dim.btn-outline-dark {
  color: #1f2b3a;
  background-color: #e6e8e9;
  border-color: #b1b5ba;
}

.btn-dim.btn-outline-dark:not(:disabled):not(.disabled):hover {
  color: #fff;
  background-color: #1f2b3a;
  border-color: #1f2b3a;
}

.btn-white.btn-outline-dark:not(.btn-dim):not(:disabled):not(.disabled):hover,
.btn-trans.btn-outline-dark:not(.btn-dim):not(:disabled):not(.disabled):hover {
  color: #1f2b3a;
  background: #e6e8e9;
}

.btn-dim.btn-outline-light {
  color: #526484;
  background-color: #f5f6fa;
  border-color: #dbdfea;
}

.btn-dim.btn-outline-light:not(:disabled):not(.disabled):hover {
  color: #fff;
  background-color: #526484;
  border-color: #526484;
}

.btn-white.btn-outline-light:not(.btn-dim):not(:disabled):not(.disabled):hover,
.btn-trans.btn-outline-light:not(.btn-dim):not(:disabled):not(.disabled):hover {
  color: #526484;
  background: #f5f6fa;
}

.btn-dim.btn-primary {
  color: #6576ff;
  background-color: #eef0ff;
  border-color: transparent;
}

.btn-dim.btn-primary:not(:disabled):not(.disabled):hover {
  color: #fff;
  background-color: #6576ff;
  border-color: #6576ff;
}

.btn-dim.btn-secondary {
  color: #364a63;
  background-color: #e9ebee;
  border-color: transparent;
}

.btn-dim.btn-secondary:not(:disabled):not(.disabled):hover {
  color: #fff;
  background-color: #364a63;
  border-color: #364a63;
}

.btn-dim.btn-success {
  color: #1ee0ac;
  background-color: #e6fcf6;
  border-color: transparent;
}

.btn-dim.btn-success:not(:disabled):not(.disabled):hover {
  color: #fff;
  background-color: #1ee0ac;
  border-color: #1ee0ac;
}

.btn-dim.btn-warning {
  color: #f4bd0e;
  background-color: #fef8e4;
  border-color: transparent;
}

.btn-dim.btn-warning:not(:disabled):not(.disabled):hover {
  color: #fff;
  background-color: #f4bd0e;
  border-color: #f4bd0e;
}

.btn-dim.btn-info {
  color: #09c2de;
  background-color: #e4f8fb;
  border-color: transparent;
}

.btn-dim.btn-info:not(:disabled):not(.disabled):hover {
  color: #fff;
  background-color: #09c2de;
  border-color: #09c2de;
}

.btn-dim.btn-danger {
  color: #e85347;
  background-color: #fceceb;
  border-color: transparent;
}

.btn-dim.btn-danger:not(:disabled):not(.disabled):hover {
  color: #fff;
  background-color: #e85347;
  border-color: #e85347;
}

.btn-dim.btn-gray {
  color: #3c4d62;
  background-color: #eaebee;
  border-color: transparent;
}

.btn-dim.btn-gray:not(:disabled):not(.disabled):hover {
  color: #fff;
  background-color: #3c4d62;
  border-color: #3c4d62;
}

.btn-dim.btn-dark {
  color: #1f2b3a;
  background-color: #e6e8e9;
  border-color: transparent;
}

.btn-dim.btn-dark:not(:disabled):not(.disabled):hover {
  color: #fff;
  background-color: #1f2b3a;
  border-color: #1f2b3a;
}

.btn-dim.btn-light {
  color: #8091a7;
  background-color: #f1f3f5;
  border-color: transparent;
}

.btn-dim.btn-light:not(:disabled):not(.disabled):hover {
  color: #fff;
  background-color: #8091a7;
  border-color: #8091a7;
}

.btn-dim.btn-lighter {
  color: #b7c2d0;
  background-color: #f7f8fa;
  border-color: transparent;
}

.btn-dim.btn-lighter:not(:disabled):not(.disabled):hover {
  color: #101924;
  background-color: #b7c2d0;
  border-color: #b7c2d0;
}

.btn-trans.btn {
  background-color: transparent;
  border-color: transparent;
}

.btn-outline-light {
  border-color: #dbdfea;
}

.btn-outline-lighter {
  border-color: #e5e9f2;
}

.btn-outline-light,
.btn-outline-lighter {
  color: #526484;
}

.btn-white,
.btn-white.btn-dim {
  background: #fff;
}

.btn-white.btn-outline-light:not(.btn-dim):not(:disabled):not(.disabled):hover {
  color: #fff;
  background: #526484;
  border-color: #526484;
}

.btn-toolbar-sep {
  border-right: 1px solid #e5e9f2;
  margin-left: 0.75rem;
  margin-right: 0.75rem;
}

.card-tools .btn-toolbar-sep {
  padding: 0 !important;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.btn-group.is-tags .btn-xs:first-child {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.btn-group.is-tags .btn-xs:last-child {
  margin-left: 0;
}

.btn-group.is-tags .btn-xs .icon {
  width: 1rem;
}

.btn-group.is-tags .btn-dim:not(:disabled):not(.disabled):hover {
  border-color: transparent;
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  display: inline-flex;
}

.dropdown-toggle {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  margin-bottom: 0;
}

.dropdown-toggle:not([class*='indicator']):after {
  display: none;
}

.clickable * {
  pointer-events: none;
}

.dropdown-menu {
  overflow: hidden;
  border: 1px solid #e5e9f2;
  box-shadow: 0 3px 12px 1px rgba(44, 55, 130, 0.15);
}

.dropdown-inner {
  padding: 0 1.75rem;
}

.dropdown-inner + .dropdown-inner {
  border-top: 1px solid #e5e9f2;
}

.dropdown-head,
.dropdown-foot {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem 1.25rem;
  border-bottom: 1px solid #e5e9f2;
}

.dropdown-head.center,
.dropdown-foot.center {
  justify-content: center;
}

.dropdown-head {
  border-bottom: 1px solid #e5e9f2;
}

.dropdown-foot {
  border-top: 1px solid #e5e9f2;
}

.dropdown-body-sm {
  padding: 0.75rem;
}

.dropdown-body-rg {
  padding: 1.25rem;
}

.dropdown-body-md {
  padding: 1.5rem;
}

.dropdown-body-lg {
  padding: 2.25rem;
}

.dropdown-title {
  font-weight: 500;
  color: #364a63;
}

[class*='dropdown-indicator']:after {
  border: none !important;
  font-family: 'Nioicon', sans-serif;
  vertical-align: middle;
  content: '';
  margin-left: 0.25rem;
  line-height: 1;
  display: inline-flex;
  align-items: center;
  font-size: 14px;
}

.dropup [class*='dropdown-indicator']:after {
  content: '';
}

.dropdown-indicator-caret:after {
  content: '';
}

.dropup .dropdown-indicator-caret:after {
  content: '';
}

.dropdown-indicator-caret-up:after {
  content: '';
}

.dropup .dropdown-indicator-caret-up:after {
  content: '';
}

.dropdown-indicator-up:after {
  content: '';
}

.dropup .dropdown-indicator-up:after {
  content: '';
}

.dropdown-indicator-down:after {
  content: '';
}

.dropup .dropdown-indicator-down:after {
  content: '';
}

.dropdown-indicator-dubble:after {
  content: '';
}

.dropup .dropdown-indicator-dubble:after {
  content: '';
}

.dropdown-indicator-unfold:after {
  content: '';
}

.dropup .dropdown-indicator-unfold:after {
  content: '';
}

.dropdown-menu-center {
  left: 50% !important;
}

.dropdown-menu-bottom {
  bottom: 0 !important;
}

.dropdown-menu-xxs {
  min-width: 70px;
  max-width: 70px;
}

.dropdown-menu-xxs.dropdown-menu-center {
  margin-left: -35px;
}

.dropdown-menu-xs {
  min-width: 120px;
  max-width: 120px;
}

.dropdown-menu-xs.dropdown-menu-center {
  margin-left: -60px;
}

.dropdown-menu-md {
  min-width: 280px;
  max-width: 280px;
}

.dropdown-menu-md.dropdown-menu-center {
  margin-left: -140px;
}

.dropdown-menu-sm {
  min-width: 140px;
  max-width: 140px;
}

.dropdown-menu-sm.dropdown-menu-center {
  margin-left: -70px;
}

.dropdown-menu-lg {
  min-width: 320px;
  max-width: 320px;
}

.dropdown-menu-lg.dropdown-menu-center {
  margin-left: -160px;
}

.dropdown-menu-xl {
  min-width: 360px;
  max-width: 360px;
}

.dropdown-menu-xl.dropdown-menu-center {
  margin-left: -180px;
}

.dropdown-menu-auto {
  min-width: 100%;
  max-width: 100%;
}

.dropdown-menu-mxh {
  max-height: 400px;
  overflow: auto;
}

.nk-quick-nav .dropdown-menu {
  margin-top: 11px;
  margin-right: -6px;
}

@media (max-width: 420px) {
  .dropdown-menu-xl,
  .dropdown-menu-lg,
  .dropdown-menu-md {
    min-width: calc(100vw - 40px);
    max-width: calc(100vw - 40px);
  }
  .nk-quick-nav .dropdown-menu-xl,
  .nk-quick-nav .dropdown-menu-lg,
  .nk-quick-nav .dropdown-menu-md {
    margin-right: 0;
    margin-left: 16px;
  }
  .nk-quick-nav li:last-child .dropdown-menu-xl,
  .nk-quick-nav li:last-child .dropdown-menu-lg,
  .nk-quick-nav li:last-child .dropdown-menu-md {
    margin-left: 0;
    margin-right: 3px;
  }
}

.dropdown-menu-s1 {
  border-top: 3px solid #6576ff;
}

.card + .card {
  margin-top: 28px;
}

.card + .nk-block-head {
  padding-top: 2.5rem;
}

.card-full {
  height: 100%;
}

.card.is-dark {
  background: #2c3782;
}

.card-bordered {
  border: 1px solid #dbdfea;
}

.card-bordered.is-dark {
  border-color: #2c3782;
}

.card-bordered.dashed {
  border-style: dashed;
}

.card-inner {
  padding: 1.25rem;
}

.card-inner-sm {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.card-inner-group .card-inner:not(:last-child) {
  border-bottom: 1px solid #dbdfea;
}

.card-head {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: -0.25rem -0.5rem 1rem;
}

.card-head > * {
  padding: 0.25rem 0.5rem;
}

.card-head > .title:only-child {
  width: 100%;
}

.card-title-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.card-title-group .card-title {
  margin-bottom: 0;
}

.card-title-group:only-child,
.card-title-group .card-search {
  margin-top: -0.25rem;
  margin-bottom: -0.25rem;
}

.card-title h6.title:not(:last-child) {
  margin-bottom: 0.25rem;
}

.card-title p {
  font-size: 12px;
  color: #8094ae;
}

.card-title p .icon {
  line-height: inherit;
  vertical-align: middle;
}

.card-title-sm .title {
  font-size: 14px;
}

.card-title small {
  font-weight: 500;
  color: #8094ae;
}

.card-subtitle {
  color: #8094ae;
  font-family: Roboto, sans-serif;
  font-weight: normal;
}

.card-tools-nav {
  display: flex;
  margin: -6px -10px -5px;
}

.card-tools-nav li {
  padding: 0 10px;
}

.card-tools-nav li a {
  position: relative;
  color: #8094ae;
  padding: 10px 0;
  display: block;
  line-height: 1;
}

.card-tools-nav li a:before {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 2px;
  content: '';
  background: #6576ff;
  opacity: 0;
}

.card-tools-nav li a:hover,
.card-tools-nav li a.active {
  color: #526484;
}

.card-tools-nav li a.active::before {
  opacity: 1;
}

.card-tools-nav li.active a {
  color: #526484;
}

.card-tools-nav li.active a::before {
  opacity: 1;
}

.card-tools.mr-n1 {
  margin-right: -0.5rem !important;
}

.card-hint {
  font-size: 1rem;
  color: #dbdfea;
}

@media (max-width: 575.98px) {
  .card-head.ui-v2 .card-tools {
    width: calc(100% + 20px);
    margin: 0 -10px;
    padding-top: 0.5rem;
  }
  .card-head.ui-v2 .card-tools-nav {
    padding: 0 0.75rem;
    border-bottom: 1px solid #e5e9f2;
  }
  .card-head.ui-v2 .card-tools-nav li a {
    padding-bottom: 1rem;
  }
  .card-stretch {
    margin-left: -18px;
    margin-right: -18px;
    border-left: 0;
    border-right: 0;
    border-radius: 0;
  }
}

@media (min-width: 576px) {
  .card-inner {
    padding: 1.5rem;
  }
  .card-inner-sm {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
  .card-inner-md {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .card-header {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

@media (min-width: 768px) {
  .card-inner-lg {
    padding: 2.5rem;
  }
  .card-inner-xl {
    padding: 3.75rem;
  }
}

.form-icon {
  position: absolute;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  top: 50%;
  transform: translateY(-50%);
  width: calc(1rem + 24px);
  height: calc(1rem + 24px);
}

.form-icon .icon {
  font-size: 16px;
  color: #8094ae;
}

.form-icon + .form-control {
  padding-left: calc(1rem + 24px);
}

.form-icon-right {
  left: auto;
  right: -1px;
}

.form-icon-right + .form-control {
  padding-left: 1rem;
  padding-right: calc(1rem + 24px);
}

.form-info {
  position: absolute;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-left: 1px solid #dbdfea;
  right: 0;
  top: 50%;
  padding: 0.125rem 1.25rem;
  transform: translateY(-50%);
  color: #8094ae;
}

.form-info + .form-control {
  padding-right: calc(1rem + 24px);
}

.form-label {
  font-size: 0.875rem;
  font-weight: 500;
  color: #344357;
  margin-bottom: 0.5rem;
}

.form-label-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.form-label-group .form-label {
  margin-bottom: 0;
}

.form-label + .form-note {
  margin-top: -0.35rem;
}

.form-label-outlined {
  position: absolute;
  top: 0.4375rem;
  left: 0.66667rem;
  transform-origin: left;
  padding: 0 0.4rem;
  font-weight: 400;
  line-height: 1.25rem;
  color: #3c4d62;
  background-color: #fff;
  z-index: 1;
  transition: all 0.3s ease;
  margin-bottom: 0;
  font-size: 0.8125rem;
}

.form-control-lg ~ .form-label-outlined {
  top: 0.6875rem;
  padding: 0 0.37736rem;
  font-size: 0.9375rem;
}

.form-control-sm ~ .form-label-outlined {
  font-size: 0.75rem;
  top: 0.25rem;
  left: 0.4rem;
}

.focused .form-control-sm ~ .form-label-outlined,
.form-select.form-control-sm ~ .form-label-outlined {
  font-size: 11px;
}

.focused .form-label-outlined,
.form-select ~ .form-label-outlined {
  top: calc(-0.4375rem + -2px);
  font-size: 11px;
}

.form-control-xl ~ .form-label-outlined {
  font-size: 0.9375rem;
  line-height: 2rem;
  top: 0.625rem;
  padding: 0 0.5rem;
  left: 0.83333rem;
}

.focused .form-control-xl ~ .form-label-outlined,
.form-select.form-control-xl ~ .form-label-outlined {
  top: calc(-0.625rem + -5px);
}

.focused .form-control-lg ~ .form-label-outlined,
.focused .form-control-xl ~ .form-label-outlined,
.form-select.form-control-lg ~ .form-label-outlined,
.form-select.form-control-xl ~ .form-label-outlined {
  font-size: 12px;
}

.form-note {
  font-size: 12px;
  color: #8094ae;
  font-style: italic;
  display: block;
}

.form-note-alt {
  font-size: 12px;
  color: #8094ae;
}

.form-note-group {
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;
  margin-bottom: -0.25rem;
}

.form-control-xl {
  height: calc(3.25rem + 2px);
  padding: 0.625rem 1.25rem;
  font-size: 0.9375rem;
  line-height: 2rem;
  border-radius: 5px;
}

.form-control-outlined {
  cursor: pointer;
}

.focused .form-control-outlined {
  cursor: auto;
}

.form-control-wrap,
.form-control-group {
  position: relative;
}

.form-control-wrap + .form-note,
.form-control-group + .form-note {
  margin-top: 0.5rem;
}

.form-control-noborder {
  border-color: transparent;
}

.form-control-noborder:focus {
  border-color: #dbdfea;
  box-shadow: none;
}

.form-control-solid {
  border-color: #fff;
}

.form-control-solid:focus {
  box-shadow: none;
  border-color: #dbdfea;
}

.form-control-number {
  font-size: 20px;
  padding: 20px;
  height: 74px;
}

.form-control-password-big {
  font-size: 44px;
  height: calc(1rem * 2 + 1.25rem + 1px);
  padding: 1rem 1rem;
}

.form-control.error {
  border-color: #e85347;
}

.form-control.error:focus {
  box-shadow: 0 0 0 3px rgba(232, 83, 71, 0.1);
}

.form-control.focus {
  border-color: #6576ff;
  box-shadow: 0 0 0 3px rgba(101, 118, 255, 0.1);
}

.form-control-simple {
  border: none;
  padding: 0;
}

.form-control-simple:focus {
  box-shadow: none;
}

.form-group {
  position: relative;
  margin-bottom: 1.25rem;
}

.form-group:last-child {
  margin-bottom: 0;
}

.form-select {
  height: calc(2.125rem + 2px);
}

.form-select:not(.form-control) {
  opacity: 0;
}

.form-select-sm,
.form-select[data-ui*='sm'] {
  height: calc(1.75rem + 2px);
}

.form-select-lg,
.form-select[data-ui*='lg'] {
  height: calc(2.625rem + 2px);
}

.form-select-xl,
.form-select[data-ui*='xl'] {
  height: calc(3.25rem + 2px);
}

.form-focus-none:focus {
  border-color: transparent;
  box-shadow: none;
}

.form-action .btn + .btn {
  margin-left: 1.25rem;
}

.form-round {
  border-radius: 100px;
}

.custom-select {
  appearance: none;
  -webkit-appearance: none;
}

.custom-select option {
  border-radius: 3px;
  padding: 5px 0.5rem;
  margin: 2px -0.5rem;
  cursor: pointer;
  color: #526484;
}

.custom-select option:last-child {
  margin-bottom: -0.5rem;
}

.form-control-select {
  position: relative;
}

.form-control-select .form-control {
  appearance: none;
  -webkit-appearance: none;
}

.form-control-select-multiple .custom-select {
  padding-top: 0.375rem;
  padding-bottom: 0.875rem;
}

.form-control-select-multiple .custom-select option {
  border-radius: 3px;
  padding: 5px 0.5rem;
  margin: 2px -0.5rem;
  cursor: pointer;
  color: #526484;
}

.form-control-select-multiple .custom-select option:last-child {
  margin-bottom: -0.5rem;
}

.form-control-select:after {
  font-family: 'Nioicon', sans-serif;
  content: '';
  pointer-events: none;
  position: absolute;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: calc(1rem + 12px);
  height: calc(1rem + 12px);
}

textarea.no-resize {
  resize: none;
}

textarea.form-control {
  min-height: 122px;
  padding-top: 0.65625rem;
}

textarea.textarea-sm {
  min-height: 92px;
}

textarea.textarea-lg {
  min-height: 208px;
}

textarea.textarea-auto {
  min-height: 0;
}

.custom-file-input ~ .error,
.custom-file-input ~ .invalid,
.form-control ~ .error,
.form-control ~ .invalid {
  color: #e85347;
  font-size: 11px;
  font-style: italic;
}

.custom-btn-check {
  position: relative;
}

.custom-btn-check input {
  position: absolute;
  opacity: 0;
  height: 1px;
  width: 1px;
}

.custom-btn-check input:checked ~ label {
  color: #fff;
  border-color: #6576ff;
  background-color: #6576ff;
}

.custom-btn-check label {
  display: inline-block;
  border: 2px solid #ebeef2;
  background-color: #ebeef2;
  font-weight: 500;
  color: #526484;
  text-align: center;
  padding: 0.4375rem 1.125rem;
  font-size: 0.8125rem;
  line-height: 1.25rem;
  border-radius: 4px;
  transition: all 0.3s;
  margin-bottom: 0 !important;
}

.custom-btn-sm label {
  padding: 0.25rem 1rem;
  font-size: 0.75rem;
  line-height: 1.25rem;
}

.custom-btn-round label {
  border-radius: 60px;
}

.custom-btn-outline label {
  background-color: transparent;
}

.custom-control {
  display: inline-flex;
  min-height: 1.5rem;
}

.custom-control.notext {
  margin-left: -0.75rem;
}

.custom-control.notext .custom-control-label {
  position: relative;
  left: 0.75rem;
}

.custom-control-label {
  font-size: 14px;
  line-height: 1.25rem;
  padding-top: 0.125rem;
}

.custom-control-label::before,
.custom-control-label::after {
  top: 0;
}

.custom-control-label:after {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-image: none !important;
  font-family: 'Nioicon', sans-serif;
  color: #fff;
  opacity: 0;
}

.custom-checkbox .custom-control-label:after {
  content: '';
}

.custom-radio .custom-control-label:after {
  content: '';
}

.custom-control .custom-control-input:checked ~ .custom-control-label::after {
  opacity: 1;
}

.custom-control .custom-control-input[disabled] ~ .custom-control-label,
.custom-control .custom-control-input:disabled ~ .custom-control-label {
  opacity: 0.7;
  color: #364a63;
}

.custom-control-sm {
  min-height: 1.125rem;
  padding-left: 1.625rem;
}

.custom-control-sm.notext {
  margin-left: -0.5rem;
}

.custom-control-sm.notext .custom-control-label {
  left: 0.5rem;
}

.custom-control-sm .custom-control-label {
  font-size: 12px;
  line-height: 1.125rem;
  padding-top: 0;
}

.custom-control-sm .custom-control-label::before,
.custom-control-sm .custom-control-label::after {
  left: -1.625rem;
  width: 1.125rem;
  height: 1.125rem;
}

.custom-control-sm .custom-control-label:after {
  font-size: 0.9em;
}

.custom-control-lg {
  min-height: 2rem;
  padding-left: 3rem;
}

.custom-control-lg.notext {
  margin-left: -1rem;
}

.custom-control-lg.notext .custom-control-label {
  left: 1rem;
}

.custom-control-lg .custom-control-label {
  font-size: 15px;
  line-height: 1.375rem;
  padding-top: 0.3125rem;
}

.custom-control-lg .custom-control-label::before,
.custom-control-lg .custom-control-label::after {
  left: -3rem;
  width: 2rem;
  height: 2rem;
}

.custom-control-lg .custom-control-label:after {
  font-size: 1.2em;
}

.custom-control-input {
  position: absolute;
  height: 1px;
  width: 1px;
  opacity: 0;
}

.custom-control.is-right {
  padding-left: 0;
  padding-right: 2.25rem;
}

.custom-control.is-right .custom-control-label:before,
.custom-control.is-right .custom-control-label:after {
  left: auto;
  right: -2.25rem;
}

.custom-control.is-right.custom-control-sm {
  padding-right: 1.625rem;
}

.custom-control.is-right.custom-control-sm .custom-control-label:before,
.custom-control.is-right.custom-control-sm .custom-control-label:after {
  right: -1.625rem;
}

.custom-control.is-right.custom-control-lg {
  padding-right: 3rem;
}

.custom-control.is-right.custom-control-lg .custom-control-label:before,
.custom-control.is-right.custom-control-lg .custom-control-label:after {
  right: -3rem;
}

.custom-file-label {
  white-space: nowrap;
  overflow: hidden;
}

.custom-select {
  background: #fff;
}

.custom-switch {
  padding-left: 3.75rem;
}

.custom-switch .custom-control-label::before {
  left: -3.75rem;
  width: 3rem;
  border-radius: 1.5rem;
}

.custom-switch .custom-control-label::after {
  opacity: 1;
  top: 0.25rem;
  left: calc(-3.75rem + 4px);
  width: 1rem;
  height: 1rem;
  border-radius: 1.5rem;
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  transform: translateX(calc(2rem - 8px));
}

.custom-switch.custom-control-sm {
  padding-left: 2.46875rem;
}

.custom-switch.custom-control-sm .custom-control-label::before {
  left: -2.46875rem;
  width: 1.96875rem;
  border-radius: 0.98438rem;
}

.custom-switch.custom-control-sm .custom-control-label::after {
  top: 0.2375rem;
  left: calc(-2.46875rem + 4px);
  width: 0.65rem;
  height: 0.65rem;
  border-radius: 0.98438rem;
}

.custom-switch.custom-control-sm
  .custom-control-input:checked
  ~ .custom-control-label::after {
  transform: translateX(calc(1.31875rem - 8px));
}

.custom-switch.custom-control-lg {
  padding-left: 5rem;
}

.custom-switch.custom-control-lg .custom-control-label::before {
  left: -5rem;
  width: 4rem;
  border-radius: 2rem;
}

.custom-switch.custom-control-lg .custom-control-label::after {
  top: 0.375rem;
  left: calc(-5rem + 6px);
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 2rem;
}

.custom-switch.custom-control-lg
  .custom-control-input:checked
  ~ .custom-control-label::after {
  transform: translateX(calc(2.75rem - 12px));
}

.custom-switch.is-right {
  padding-left: 0;
  padding-right: 3.75rem;
}

.custom-switch.is-right .custom-control-label:before,
.custom-switch.is-right .custom-control-label:after {
  left: auto;
  right: -2.25rem;
}

.custom-switch.is-right .custom-control-label::before {
  right: -3.75rem;
}

.custom-switch.is-right .custom-control-label::after {
  right: calc(-3.75rem + 4px);
}

.custom-switch.is-right
  .custom-control-input:checked
  ~ .custom-control-label::after {
  transform: translateX(calc((-2rem + 8px)));
}

.custom-switch.is-right.custom-control-sm {
  padding-right: 2.46875rem;
}

.custom-switch.is-right.custom-control-sm .custom-control-label::before {
  right: -2.46875rem;
}

.custom-switch.is-right.custom-control-sm .custom-control-label::after {
  right: calc(-2.46875rem + 4px);
}

.custom-switch.is-right.custom-control-sm
  .custom-control-input:checked
  ~ .custom-control-label::after {
  transform: translateX(calc((-1.31875rem + 8px)));
}

.custom-switch.is-right.custom-control-lg {
  padding-right: 5rem;
}

.custom-switch.is-right.custom-control-lg .custom-control-label::before {
  right: -5rem;
}

.custom-switch.is-right.custom-control-lg .custom-control-label::after {
  right: calc(-5rem + 6px);
}

.custom-switch.is-right.custom-control-lg
  .custom-control-input:checked
  ~ .custom-control-label::after {
  transform: translateX(calc((-2.75rem + 12px)));
}

label {
  cursor: pointer;
}

input[type='radio']:checked ~ label {
  cursor: default;
}

.is-alter .form-control,
.custom-control {
  position: relative;
}

.is-alter .form-control ~ .invalid,
.custom-control .invalid {
  position: absolute;
  left: 0;
  color: #fff;
  font-size: 11px;
  line-height: 1;
  bottom: calc(100% + 4px);
  background: #ed756b;
  padding: 0.3rem 0.5rem;
  z-index: 1;
  border-radius: 3px;
  white-space: nowrap;
}

.is-alter .form-control ~ .invalid:before,
.custom-control .invalid:before {
  position: absolute;
  content: '';
  height: 0;
  width: 0;
  border-left: 6px solid #ed756b;
  border-right: 6px solid transparent;
  left: 10px;
  border-bottom: 6px solid transparent;
  bottom: -6px;
}

.is-alter .form-control ~ .invalid {
  left: auto;
  right: 0;
}

.is-alter .form-control ~ .invalid::before {
  left: auto;
  right: 10px;
  border-right-color: #ed756b;
  border-left-color: transparent;
  bottom: -4px;
}

.table thead tr:last-child th {
  border-bottom: 1px solid #dbdfea;
}

.table-dark thead tr:last-child th,
.table thead.thead-dark tr:last-child th {
  border-color: #3c4d62;
}

.table td:first-child,
.table th:first-child {
  padding-left: 1.25rem;
}

.table td:last-child,
.table th:last-child {
  padding-right: 1.25rem;
}

.table th {
  line-height: 1.1;
}

.table th.overline-title {
  line-height: 1.25rem;
  font-weight: 500;
}

.table caption {
  font-style: italic;
  padding-left: 1.25rem;
}

.table-bordered td,
.table-bordered th {
  padding-left: 1rem;
  padding-right: 1rem;
}

.table-plain td:first-child,
.table-plain th:first-child {
  padding-left: 0;
}

.table-plain td:last-child,
.table-plain th:last-child {
  padding-right: 0;
}

.table-lg td {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.table-lg th {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.table-vm td,
.table-vm th,
.table td.vm,
.table th.vm {
  vertical-align: middle;
}

@media (min-width: 576px) {
  .table-lg td:first-child,
  .table-lg th:first-child {
    padding-left: 1.5rem;
  }
  .table-lg td:last-child,
  .table-lg th:last-child {
    padding-right: 1.5rem;
  }
}

.media-center {
  align-items: center;
}

.media-object {
  display: inline-flex;
}

.media > *:first-child {
  flex-shrink: 0;
}

.media > *:last-child {
  flex-grow: 1;
  max-width: 100%;
}

.nav {
  margin: -0.5rem -1rem;
}

.nav .nav-link {
  display: inline-flex;
  align-items: center;
}

.nav .nav-link .icon + span,
.nav .nav-link span + .icon {
  margin-left: 0.75rem;
}

.nav .nav-link .icon {
  font-size: 1.3em;
}

.nav-tabs {
  font-family: var(--default-font), sans-serif;
  margin: 0;
  font-size: 0.8125rem;
  border-bottom: 1px solid #dbdfea;
}

.nav-tabs-s2 {
  border-bottom: none;
  justify-content: center;
}

.nav-tabs + .tab-content {
  margin-top: 1.25rem;
}

.nav-tabs .nav-item {
  padding-right: 1.25rem;
}

.nav-tabs .nav-item:last-child {
  padding-right: 0;
}

.nav-tabs .nav-item.active .nav-link {
  color: #6576ff;
}

.nav-tabs .nav-item.active .nav-link:after {
  opacity: 1;
}

.nav-tabs .nav-link {
  padding: 1rem 0;
  font-weight: 700;
  color: #526484;
  position: relative;
  border: none;
  outline: none;
  display: inline-flex;
  align-items: center;
}

.nav-tabs .nav-link .icon {
  font-size: 1.4em;
}

.nav-tabs .nav-link .icon + span {
  margin-left: 0.5rem;
}

.nav-tabs .nav-link .badge {
  margin-left: 0.25rem;
}

.nav-tabs .nav-link:hover {
  color: #364a63;
}

.nav-tabs .nav-link:focus {
  color: #6576ff;
}

.nav-tabs .nav-link:before,
.nav-tabs .nav-link:after {
  position: absolute;
  content: '';
}

.nav-tabs .nav-link:after {
  left: 0;
  right: 0;
  bottom: 0;
  height: 3px;
  background: #6576ff;
  opacity: 0;
}

.nav-tabs .nav-link.active {
  color: #6576ff;
  border: none;
  background-color: transparent;
}

.nav-tabs .nav-link.active:after {
  opacity: 1;
}

.nav-sm {
  font-size: 0.8125rem;
}

@media (min-width: 768px) {
  .nav-tabs .nav-item {
    padding-right: 1.5rem;
  }
  .nav-tabs-sm .nav-item {
    padding-right: 1.25rem;
  }
}

@media (min-width: 992px) {
  .nav-tabs .nav-item {
    padding-right: 1.75rem;
  }
  .nav-tabs-sm .nav-item {
    padding-right: 1.25rem;
  }
  .nav-tabs .nav-link {
    font-size: 0.875rem;
  }
}

@media (min-width: 1200px) {
  .nav-tabs .nav-item {
    padding-right: 2.25rem;
  }
  .nav-tabs-sm .nav-item {
    padding-right: 1.25rem;
  }
}

@media (max-width: 575.98px) {
  .nav-tabs.is-stretch {
    margin-left: -18px;
    margin-right: -18px;
    padding-left: 18px;
    padding-right: 18px;
  }
}

.nav-item-trigger {
  display: inline-flex;
  align-items: center;
  margin: -1px -0.5rem 0 auto;
}

.nav-tabs-card {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  font-family: Roboto, sans-serif;
}

@media (min-width: 576px) {
  .nav-tabs-card {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

@media (max-width: 767.98px) {
  .nav-tabs-mb-icon .nav-item {
    padding-right: 0.75rem;
  }
  .nav-tabs-mb-icon .nav-link .icon {
    font-size: 1.25rem;
    padding: 0 0.5rem;
  }
  .nav-tabs-mb-icon .nav-link .icon + span {
    display: none;
  }
}

@media (max-width: 359px) {
  .nav-tabs-mb-icon .nav-item {
    padding-right: 0.35rem;
  }
}

.is-medium.nav-tabs .nav-link {
  padding: 0.75rem 0;
}

.modal-content {
  position: relative;
  min-height: 40px;
  box-shadow: 0px 0px 1px 0px rgba(82, 100, 132, 0.2),
    0px 8px 15.52px 0.48px rgba(28, 43, 70, 0.15);
}

.modal-content > .close {
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
  height: 2.25rem;
  width: 2.25rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #526484;
  z-index: 1;
  transition: all 0.3s;
}

.modal-body .stretched {
  border-radius: 0;
  margin-left: -1.25rem;
  margin-right: -1.25rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.modal-body .stretched.alert {
  border: none;
}

.modal-body .stretched.alert-icon {
  padding-left: 3.25rem;
}

.modal-body-md {
  padding: 1.75rem 1.25rem;
}

.modal-body-md .stretched {
  margin-left: -1.25rem;
  margin-right: -1.25rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.modal-body-md .stretched.alert-icon {
  padding-left: 4.5rem;
}

.modal-body-lg {
  padding: 2.25rem 1.25rem;
}

.modal-body-lg .stretched {
  margin-left: -1.25rem;
  margin-right: -1.25rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.modal-body-lg .stretched.alert-icon {
  padding-left: 4.5rem;
}

.modal-footer-stretch {
  justify-content: stretch;
}

.modal-footer-between {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: -0.25rem 0;
}

.modal-footer-between > div {
  padding: 0.25rem 0;
}

.modal-dialog {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-dialog::before {
  height: calc(100vh - 1rem);
}

.modal.fade .modal-dialog {
  transform: translate(0, -10px);
}

.modal.zoom .modal-dialog {
  transform: scale(0.95);
  transition: transform 0.3s ease-out;
}

.modal-dialog-top {
  align-items: flex-start;
}

.modal.fade .modal-dialog-top {
  transform: translate(0, -30px);
}

.modal-dialog-bottom {
  align-items: flex-end;
}

@media (min-width: 576px) {
  .modal-dialog {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog::before {
    height: calc(100vh - 3.5rem);
  }
  .modal-body {
    padding: 1.5rem 1.5rem;
  }
  .modal-body .stretched {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .modal-body .stretched.alert-icon {
    padding-left: 3.5rem;
  }
  .modal-body-sm {
    padding: 1.25rem 1.25rem;
  }
  .modal-body-sm .stretched {
    margin-left: -1.25rem;
    margin-right: -1.25rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
  .modal-body-sm .stretched.alert-icon {
    padding-left: 3.25rem;
  }
  .modal-body-md {
    padding: 2.25rem 2.5rem;
  }
  .modal-body-md .stretched {
    margin-left: -2.5rem;
    margin-right: -2.5rem;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
  .modal-body-md .stretched.alert-icon {
    padding-left: 4.5rem;
  }
  .modal-body-md .stretched.alert-icon > .icon {
    left: 2.5rem;
  }
  .modal-body-lg {
    padding: 3.75rem 3.75rem;
  }
  .modal-body-lg .stretched {
    margin-left: -3.75rem;
    margin-right: -3.75rem;
    padding-left: 3.75rem;
    padding-right: 3.75rem;
  }
  .modal-body-lg .stretched.alert-icon {
    padding-left: 5.75rem;
  }
  .modal-header {
    padding: 1rem 1.5rem;
  }
  .modal-header-sm {
    padding: 0.75rem 1.25rem;
  }
  .modal-footer {
    padding: 1rem 1.5rem;
  }
  .modal-footer-sm {
    padding: 0.75rem 1.25rem;
  }
}

@media (min-width: 768px) {
  .modal-md {
    max-width: 600px;
  }
}

.pagination-goto {
  display: inline-flex;
  align-items: center;
  font-size: 0.8125rem;
  text-transform: uppercase;
}

.page-link {
  font-size: 0.8125rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: calc(1rem + 1.125rem + 2px);
}

.pagination-sm .page-link {
  line-height: 0.875rem;
  min-width: calc(0.875rem + 0.875rem + 2px);
}

.pagination-lg .page-link {
  line-height: 1.5rem;
  min-width: calc(1.5rem + 1.125rem + 2px);
}

.pagination-xl .page-link {
  padding: 0.875rem 0.75rem;
  font-size: 1.38125rem;
  line-height: 1.5rem;
  min-width: calc(1.5rem + 1.75rem + 2px);
}

.page-link .icon {
  font-size: 0.8125rem;
  line-height: inherit;
}

.page-link:not([href]) {
  background-color: transparent;
  color: inherit;
}

.page-link:not([href]) .icon:before {
  position: relative;
  top: 1px;
}

.page-link-prev span,
.page-link-next span {
  display: none;
}

@media (min-width: 576px) {
  .page-link-prev span,
  .page-link-next span {
    display: block;
  }
  .page-link-prev .icon {
    padding-right: 0.25rem;
  }
  .page-link-next .icon {
    padding-left: 0.25rem;
  }
}

.progress-bar {
  height: 100%;
  line-height: 1;
}

.progress-sm {
  height: 0.25rem;
}

.progress-md {
  height: 0.375rem;
}

.progress-lg {
  height: 1rem;
}

.progress-pill,
.progress-pill .progress-bar {
  border-radius: 100px;
}

.progress-text {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #8094ae;
  margin-bottom: 0.25rem;
}

.progress-label {
  font-size: 0.875rem;
}

.progress-amount {
  font-size: 12px;
  padding-left: 0.5rem;
}

.progress-alt {
  display: flex;
  align-items: center;
  overflow: visible;
}

.progress-alt .progress-bar {
  border-radius: 2px;
}

.progress-reverse {
  flex-direction: row-reverse;
}

.progress-bordered .progress-bar {
  margin-top: -2px;
  border: 2px solid #fff;
  box-sizing: content-box;
}

.progress-bordered .progress-bar:not(:first-child) {
  margin-left: -4px;
}

.progress-bordered.progress-reverse .progress-bar {
  margin-left: 0;
}

.progress-bordered.progress-reverse .progress-bar:not(:last-child) {
  margin-left: -5px;
}

.toast .close {
  display: inline-flex;
  margin-right: -0.5rem;
  margin-left: 0.5rem;
}
