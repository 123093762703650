#society-modal .modal-body {
  padding: 0 !important;
}

textarea.form-control {
  min-height: 64px;
}

input[type="file" i] {
  color: #6576ff;
  background-color: #6576ff;
  border-color: #6576ff;
}

input::-webkit-file-upload-button:link,
input::-webkit-file-upload-button:visited,
input::-webkit-file-upload-button:hover,
input::-webkit-file-upload-button:active
input::-webkit-file-upload-button:focus
{
  border: none !important;
  box-shadow: none !important;
}

input::-webkit-file-upload-button {
  outline: none;
  background-color: #6576ff;
  border: none;
  // opacity: 0;
  width: 100%;
  margin: 0;
  padding: 0;
  color: #fff;
}

input::-webkit-file-upload-button:focus {
  border: red !important;

}

#society-canvas {
  border-radius: 50%;
}
