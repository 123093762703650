@import './Variables';

.is-disable {
  color: lightgray !important;
  pointer-events: none !important;
}

.no-cursor-pointer {
  pointer-events: none !important;
}

.cursor-default {
  cursor: default !important;
}

.cursor-help {
  cursor: help !important;
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}

p.link,
p.nav-link,
.cursor-pointer {
  cursor: pointer;
}

.accordion .btn-link {
  color: #6e82a5;
}

.accordion .btn-link:hover {
  color: #798bff;
}

.btn:focus {
  box-shadow: none;
}

.report-button a {
  color: #fff !important;
}

.button-light {
  color: #526484;
}

.button-light:hover {
  color: #fff !important;
}

.spinner-grow {
  margin: 0 auto;
}

.wide-xl {
  transition: max-width 0.1s linear;
  -webkit-transition: max-width 0.1s linear;
  -moz-transition: max-width 0.1s linear;
  -ms-transition: max-width 0.1s linear;
  -o-transition: max-width 0.1s linear;
}

.nk-block-embeded {
  max-width: 944px;
}

.wide-xl.app-full-width {
  width: 100% !important;
  max-width: 100% !important;
  margin-left: 0 !important;
  margin-right: 0 !important;

  .nk-block-embeded {
    max-width: calc(100vw - 350px) !important;
  }
}

.swal2-timer-progress-bar {
  background-color: rgba(101, 118, 255, 0.7) !important;
}

.mw-none {
  max-width: none !important;
}

#react-select-3-listbox {
  div[id*='-option'] {
    &:hover {
      background-color: #798bff;
      color: #fff;
      cursor: pointer;
    }
  }
}

#society__directors-table {
  .dropleft .dropdown-toggle::before {
    border: none;
  }
}

.z-0,
.z-index-0 {
  z-index: 0 !important;
}

.z-1,
.z-index-1 {
  z-index: 1 !important;
}

.mandatory-field {
  color: $text-danger;
  margin-left: 4px;
}

.table-font-sm {
  font-size: 12.5px !important;
}

.vertical-align-middle {
  vertical-align: middle !important;
}

.text-2-lines-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
