@import './assets/styles/Fonts.scss';

* {
  font-family: Inter, sans-serif;
}

a {
  text-decoration: none !important;
}

.app {
  background: #fff;

  .app__container {
    display: flex;

    padding-left: 14px;
    padding-right: 14px;
    padding-top: 100px;

    margin: 0 auto;

    max-width: 1240px;

    .container__nav {
      display: none;
    }
  }
}

@media screen and (min-width: 990px) {
  .app > .app__container > .container__nav {
    display: block;
  }
}

.container__main {
  width: 100%;
}
