.number {
  font-weight: 600;
}

.steps ul li h5 {
  border: none;
  padding: 0 0 6px 0;
  letter-spacing: 0.02em;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 500;
  color: #6576ff;
}

ul li.current {
  border-bottom: solid 2px #6576ff;
  transition: all 0.4s;
}

li[role='tab'] {
  cursor: pointer;
}

input[type='file'] {
  line-height: 28px;
}
