.card-statistics {
  text-align: center;
  width: 100%;

  span:first-child {
    font-size: 1.125rem;
    color: #1c2b46;
  }

  // select second span

  span:last-child {
    font-size: 0.875rem;
    color: #8094ae;
    margin-bottom: 12px;
  }
}
