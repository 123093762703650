@import '../../assets/styles/Fonts.scss';

.wrapper-class {
  width: 100%;
  height: 100%;
}

.editor-class {
  width: 100%;
}

#preview--box {
  color: #364a63;
  font-family: var(--default-font), sans-serif !important;

  .card-inner {
    font-family: var(--default-font), sans-serif !important;
    padding: 40px 80px;
  }

  a {
    font-family: var(--default-font), sans-serif !important;
  }

  h1 {
    font-size: 24pt;
  }

  h2 {
    font-size: 22pt;
  }

  h3 {
    font-size: 20pt;
  }

  h4 {
    font-size: 18pt;
  }

  h5 {
    font-size: 16pt;
  }
}

.wysiwyg-mention {
  color: #364a63;
  text-decoration: none;
  pointer-events: none;
  cursor: text;

  &:hover {
    color: #364a63;
  }
}
