.docx--container {
  overflow-x: hidden;
  overflow-y: auto;

  .docx-wrapper {
    background: none;
    padding: 0;
    display: flex;
    justify-content: center;

    .docx {
      zoom: 0.75;
    }
  }
}
