#custom-table-xls-button::before {
  content: '';
  margin-right: 8px;
  font-family: 'Nioicon' !important;
  font-size: 1.4em;
  font-style: normal;
  font-weight: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
}
