// import Variables.scss from styles folder
@import '../../assets/styles/Variables.scss';

$background-light: #f5f6fa;
$background-light-vert: #f5f6fa;
$background-white: #ffffff;

.table-responsive {
  .table-striped.cloned {
    width: 200px;

    th,
    td {
      padding-right: 8px !important;
    }
  }
  .table td:first-child {
    width: 200px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .w-200px {
    width: 200px !important;
    height: 30px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.w-70px {
  width: 70px !important;
}

.no-break {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.partner-book-table {
  font-size: 12px;

  thead {
    .table-header {
      text-align: center;

      th {
        font-size: 12px !important;
        line-height: 16px !important;
        vertical-align: middle !important;
        position: relative;
      }

      .w-200px {
        height: 49px !important;
      }
    }
  }

  thead td,
  tbody td,
  tfoot td {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
    height: 30px !important;
    line-height: 20px !important;
  }

  .bg-light {
    background-color: $background-light !important;
  }

  .bg-light-vert {
    background-color: $background-light-vert !important;
  }
}

#partnerClassTable,
#partnerClassTableText {
  th {
    &.holding-class-header {
      font-size: 12px !important; /* Adjust this based on your design */
      line-height: 1.5 !important; /* Adjust this based on your design */
      overflow: hidden !important;
      max-height: 50px !important;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 90px;
    }
  }
  td {
    text-align: right !important;
    padding-right: 15px !important;
    padding-left: 15px !important;
  }
}

.partnerBookTableWrapper {
  width: 100%;
  overflow: hidden;

  .table-responsive {
    overflow-x: hidden;
    overflow-y: auto;
  }

  table {
    text-align: right;
    background-color: #ffffff;
    border-right: 1px solid #dbdfea !important;
  }

  table#table-overlap {
    z-index: 9;
    box-shadow: 10px 0 15px -5px rgba(0, 0, 0, 0.1);

    .table-header th,
    .table-subheader td {
      width: 200px !important;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .table-header th {
      height: 49px !important;
      background-color: $background-light;
    }
    .table-subheader th {
      height: 30px;
    }
  }

  .table-subheader th,
  .table-subheader td {
    height: 30px !important;
    background-color: $background-light;
    border-bottom: 1px solid #dbdfea !important;

    font-size: 12px;
    font-weight: bold;
  }
}

.scroll-sync {
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
}

.top-scroll,
.bottom-scroll {
  width: 200%;
  height: 1px;
}

.content {
  overflow-y: auto;
  overflow-x: hidden !important;
  position: relative;
}
