.nk-stepper {
  display: none;
}

.nk-stepper-nav {
  display: flex;
  margin: -0.5rem;
  counter-reset: step-number;
}

.nk-stepper-nav > * {
  padding: 0.5rem;
}

.nk-stepper-nav > * .step-item {
  position: relative;
  display: flex;
  align-items: center;
}

.nk-stepper-nav > * .step-item:after,
.nk-stepper-nav > * .step-item:before {
  display: grid;
  place-items: center;
  height: 32px;
  width: 32px;
  border-radius: 100px;
  color: #6576ff;
  background: rgba(101, 118, 255, 0.15);
  margin-right: 1.125rem;
  flex-shrink: 0;
  flex-grow: 0;
}

.nk-stepper-nav > * .step-item:before {
  counter-increment: step-number;
  content: counter(step-number);
}

.nk-stepper-nav > * .step-item:after {
  font-family: Nioicon;
  content: '\e9bd';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
}

.nk-stepper-nav > .done .step-item:before {
  opacity: 0;
}

.nk-stepper-nav > .done .step-item:after {
  opacity: 1;
}

.nk-stepper-nav > .current .step-item:after,
.nk-stepper-nav > .current .step-item:before {
  color: #fff;
  background: #6576ff;
}

.nk-stepper-nav.is-vr {
  flex-direction: column;
}

.nk-stepper-nav-s2 > * .step-item {
  border-radius: 4px;
  padding: 1rem 1.25rem;
  background: #f5f6fa;
  border-left: 2px solid transparent;
  height: 100%;
}

.nk-stepper-nav-s2 > .current .step-item {
  border-left-color: #6576ff;
}

.nk-stepper-nav-s2 > .done .step-item:before {
  color: #fff;
  background: #6576ff;
}

.nk-stepper-step {
  display: block;
}

.nk-stepper-step.active {
  display: block;
}

.nk-stepper-pagination {
  display: flex;
}

.nk-stepper-s1[data-step-current='last'] .stepper-pagination {
  justify-content: center;
}

@media (min-width: 576px) {
  .nk-stepper-nav.is-vr-sm {
    flex-direction: column;
  }

  .nk-stepper-nav.is-hr-sm {
    flex-direction: row;
  }
}

@media (min-width: 768px) {
  .nk-stepper-nav.is-vr-md {
    flex-direction: column;
  }

  .nk-stepper-nav.is-hr-md {
    flex-direction: row;
  }
}

@media (min-width: 992px) {
  .nk-stepper-nav.is-vr-lg {
    flex-direction: column;
  }

  .nk-stepper-nav.is-hr-lg {
    flex-direction: row;
  }
}

@media (min-width: 1200px) {
  .nk-stepper-nav.is-vr-xl {
    flex-direction: column;
  }

  .nk-stepper-nav.is-hr-xl {
    flex-direction: row;
  }
}

@media (min-width: 1540px) {
  .nk-stepper-nav.is-vr-xxl {
    flex-direction: column;
  }

  .nk-stepper-nav.is-hr-xxl {
    flex-direction: row;
  }
}
