.modal-body {
  padding: 24px !important;
  font-family: var(--default-font), sans-serif;

  ul.nav {
    font-family: var(--default-font), sans-serif;
  }

  &.no-padding {
    padding: 0 !important;
  }
}

.isSociety {
  display: flex;
  margin-bottom: 0;
}

.isSociety .form-control {
  width: calc(1.125rem + 2px);
  display: inline;
  height: calc(1.125rem + 2px);
  margin-left: 15px;
}

.d-none {
  display: none;
}

.comms-file {
  border: 1px solid transparent;
  padding: 0.4375rem 1.125rem;
  font-size: 0.8125rem;
  line-height: 1.25rem;
  border-radius: 4px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: pointer;
}

.react-select {
  // select div inside with class that contains -control

  div[class$='cymwt-control'] {
    color: #3c4d62 !important;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #dbdfea;
    border-radius: 4px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    div[class$='-singleValue'],
    div[class$='-ValueContainer'] {
      color: #3c4d62 !important;
    }
  }

  div[class$='ipsp-control'] {
    color: #3c4d62;
    background-color: #fff;
    border-color: #6576ff;
    outline: 0;
    box-shadow: 0 0 0 3px rgba(101, 118, 255, 0.1);
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  #react-select-2-listbox {
    z-index: 2;
  }
}

.react-select-lg {
  div[class$='ipsp-control'],
  div[class$='cymwt-control'] {
    height: 44px;
  }
}

#operation-comments {
  min-height: 64px;
}
