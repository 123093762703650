.link-list-opt.no-bdr.dropdown-menu.dropdown-menu-s1 {
  padding-top: 0;
  border-top: 3px solid #6576ff !important;

  &.success {
    border-top: 3px solid #00d68f !important;
  }

  a {
    font-size: 13px !important;
  }

  a:hover {
    background-color: none !important;
  }
}
