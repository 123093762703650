.signature-wrapper {
  position: relative;
  width: 500px;
  height: 250px;
  user-select: none;

  box-sizing: content-box;
}

.signature-buttons-area {
  display: flex;
  justify-content: space-between;
  margin-top: 210px;
}

.signature-pad {
  position: absolute;
  left: 0;
  top: 0;
  width: 500px;
  height: 200px;
  border: 6px solid #eee;
  border-radius: 10px;
}
