// import Variables.scss from styles folder
@import '../../assets/styles/Variables.scss';

.table-responsive {
  tr {
    height: 42px;
  }

  .table-striped.cloned {
    width: 200px;

    th,
    td {
      padding-right: 8px !important;
    }
  }
  .table td:first-child {
    width: 200px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .w-200px {
    width: 200px !important;
    height: 42px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.w-70px {
  width: 70px !important;
}

.no-break {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.partner-book-table {
  font-size: 12px;

  tr,
  .w-200px {
    height: 30px !important;
  }

  .table-header {
    th {
      font-size: 12px !important;
      line-height: 16px !important;
      vertical-align: middle !important;
    }

    .w-200px {
      height: 50px !important;
    }
  }

  td {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
    height: 30px !important;
    line-height: 20px !important;
  }

  .table-subheader {
    text-align: center;
    font-size: 12px;
    font-weight: bold;
    line-height: 20px;
  }

  .bg-light {
    background-color: #f5f6fa !important;
  }

  .bg-light-vert {
    background-color: #f5f6fa !important;
  }
}

#partnersRegisterTable {
  background-color: red !important;
}
