#main-menu__extended {
  .btn:focus,
  .btn.focus,
  .btn:hover {
    outline: 0;
    box-shadow: none;
    text-decoration: none;
  }
}

#main-menu__extended .nk-menu-toggle,
#main-menu__collapsed .nk-menu-toggle,
#secondary-menu__fixed .nk-menu-text {
  font-weight: 600 !important;
}

.accordion {
  border: none !important;

  .has-sub {
    margin-bottom: 6px;
  }

  .nk-menu-toggle {
    height: 44px;
  }

  .nk-menu-item {
    width: 100%;
    height: 34px;

    padding: 0;

    .nk-menu-icon {
      text-align: left;
    }

    .nk-menu-text {
      text-align: left;
    }
  }

  .nk-menu-sub {
    .nk-menu-text {
      margin-left: 36px;
    }
  }

  button.btn.btn-link {
    width: 100%;
    height: 48px;

    .nk-menu-icon {
      height: 24px;
    }

    .icon.ni.ni-layers {
      font-size: 24px;
    }
  }

  .btn-link,
  a.nk-menu-link {
    padding-left: 0;
    padding-right: 0;

    text-decoration: none;
  }
}

.nk-menu.nk-menu-sm {
  margin-top: 72px;
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}
