/** 01. FONTS */
@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto-Light.eot');
  src: local('Roboto Light'), local('Roboto-Light'),
    url('../fonts/Roboto-Light.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Roboto-Light.woff2') format('woff2'),
    url('../fonts/Roboto-Light.woff') format('woff'),
    url('../fonts/Roboto-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto-Regular.eot');
  src: local('Roboto'), local('Roboto-Regular'),
    url('../fonts/Roboto-Regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Roboto-Regular.woff2') format('woff2'),
    url('../fonts/Roboto-Regular.woff') format('woff'),
    url('../fonts/Roboto-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto-Medium.eot');
  src: local('Roboto Medium'), local('Roboto-Medium'),
    url('../fonts/Roboto-Medium.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Roboto-Medium.woff2') format('woff2'),
    url('../fonts/Roboto-Medium.woff') format('woff'),
    url('../fonts/Roboto-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto-Bold.eot');
  src: local('Roboto Bold'), local('Roboto-Medium'),
    url('../fonts/Roboto-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Roboto-Bold.woff2') format('woff2'),
    url('../fonts/Roboto-Bold.woff') format('woff'),
    url('../fonts/Roboto-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Nunito';
  src: url('../fonts/Nunito-Regular.eot');
  src: local('Nunito Regular'), local('Nunito-Regular'),
    url('../fonts/Nunito-Regular.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Nunito-Regular.woff2') format('woff2'),
    url('../fonts/Nunito-Regular.woff') format('woff'),
    url('../fonts/Nunito-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Nunito';
  src: url('../fonts/Nunito-Bold.eot');
  src: local('Nunito Bold'), local('Nunito-Bold'),
    url('../fonts/Nunito-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Nunito-Bold.woff2') format('woff2'),
    url('../fonts/Nunito-Bold.woff') format('woff'),
    url('../fonts/Nunito-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

// Add Inter fonts

@font-face {
  font-family: 'Inter';
  font-weight: 100;
  src: url(../fonts/Inter-Thin.woff2) format('woff2'),
    url(../fonts/Inter-Thin.woff) format('woff');
}

@font-face {
  font-family: 'Inter';
  font-weight: 200;
  src: url(../fonts/Inter-ExtraLight.woff2) format('woff2'),
    url(../fonts/Inter-ExtraLight.woff) format('woff');
}

@font-face {
  font-family: 'Inter';
  font-weight: 300;
  src: url(../fonts/Inter-Light.woff2) format('woff2'),
    url(../fonts/Inter-Light.woff) format('woff');
}

@font-face {
  font-family: 'Inter';
  font-weight: 400;
  src: url(../fonts/Inter-Regular.woff2) format('woff2'),
    url(../fonts/Inter-Regular.woff) format('woff');
}

@font-face {
  font-family: 'Inter';
  font-weight: 500;
  src: url(../fonts/Inter-Medium.woff2) format('woff2'),
    url(../fonts/Inter-Medium.woff) format('woff');
}

@font-face {
  font-family: 'Inter';
  font-weight: 600;
  src: url(../fonts/Inter-SemiBold.woff2) format('woff2'),
    url(../fonts/Inter-SemiBold.woff) format('woff');
}

@font-face {
  font-family: 'Inter';
  font-weight: 700;
  src: url(../fonts/Inter-Bold.woff2) format('woff2'),
    url(../fonts/Inter-Bold.woff) format('woff');
}

@font-face {
  font-family: 'Inter';
  font-weight: 800;
  src: url(../fonts/Inter-ExtraBold.woff2) format('woff2'),
    url(../fonts/Inter-ExtraBold.woff) format('woff');
}

@font-face {
  font-family: 'Inter';
  font-weight: 900;
  src: url(../fonts/Inter-Black.woff2) format('woff2'),
    url(../fonts/Inter-Black.woff) format('woff');
}
